var Roster = /** @class */ (function () {
    function Roster(_name, _title, _gender, _grade, _age, _imageUrl) {
        this._name = _name;
        this._title = _title;
        this._gender = _gender;
        this._grade = _grade;
        this._age = _age;
        this._imageUrl = _imageUrl;
    }
    Object.defineProperty(Roster.prototype, "name", {
        get: function () {
            return this._name;
        },
        set: function (value) {
            this._name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Roster.prototype, "title", {
        get: function () {
            return this._title;
        },
        set: function (value) {
            this._title = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Roster.prototype, "gender", {
        get: function () {
            return this._gender;
        },
        set: function (value) {
            this._gender = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Roster.prototype, "grade", {
        get: function () {
            if (this._grade !== undefined) {
                return this._grade;
            }
            return '';
        },
        set: function (value) {
            this._grade = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Roster.prototype, "imageUrl", {
        get: function () {
            if (this._imageUrl !== undefined) {
                return this._imageUrl;
            }
            return '';
        },
        set: function (value) {
            this._imageUrl = value;
        },
        enumerable: true,
        configurable: true
    });
    Roster.prototype.toFirebase = function () {
        return {
            name: this.name,
            gender: this.gender,
            title: this.title,
            grade: this.grade,
            imageUrl: this.imageUrl
        };
    };
    return Roster;
}());
export { Roster };

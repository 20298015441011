/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./navbar/navbar.component.ngfactory";
import * as i3 from "./navbar/navbar.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "ngx-page-scroll/src/ngx-page-scroll.service";
import * as i6 from "./login.service";
import * as i7 from "@angular/router";
import * as i8 from "./footer/footer.component.ngfactory";
import * as i9 from "./footer/footer.component";
import * as i10 from "./app.component";
import * as i11 from "./ga.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-navbar", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).toggleNavbar($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NavbarComponent_0, i2.RenderType_NavbarComponent)), i1.ɵdid(1, 4243456, null, 0, i3.NavbarComponent, [i4.DOCUMENT, i5.PageScrollService, i6.LoginService, i7.Router], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "main", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-footer", [], null, null, null, i8.View_FooterComponent_0, i8.RenderType_FooterComponent)), i1.ɵdid(6, 114688, null, 0, i9.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 4, 0); _ck(_v, 6, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i10.AppComponent, [i7.Router, i6.LoginService, i11.GoogleAnalyticsEventsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i10.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };

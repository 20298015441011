import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable()
export class SeoService {

  constructor(private meta: Meta) { }
  generateTags(config) {
    config = {
      title: 'Test Test 123',
      description: 'My SEO friendly Angular Component',
      image: '',
      slug: '',
      ...config
    };
    this.meta.updateTag({name: 'twitter:card', content: 'summary'});
    this.meta.updateTag({name: 'twitter:site', content: '@blacksburgswimanddive'});
    this.meta.updateTag({name: 'twitter:title', content: config.title});
    this.meta.updateTag({name: 'twitter:description', content: config.description});
    this.meta.updateTag({name: 'twitter:image', content: config.image});

    this.meta.updateTag({name: 'og:type', content: 'article'});
    this.meta.updateTag({name: 'og:site_name', content: 'Blacksburg Swim & Dive'});
    this.meta.updateTag({name: 'og:title', content: config.title});
    this.meta.updateTag({name: 'og:description', content: config.description});
    this.meta.updateTag({name: 'og:image', content: config.image});
    this.meta.updateTag({name: 'og:url', content: `https://bhsswimanddive.com/${config.slug}`});
  }

}

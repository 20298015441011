<!--
  Edited on August 17, 2017
  Author: Brandon
  Version: 1.0-beta
-->

<section id="jumbotron">
  <div data-jarallax="{&quot;speed&quot;: 0.2}" id="top" class="title-content jarallax">
    <div id="main-title">
      <div class="main-title-content" uk-scrollspy="cls: uk-animation-fade; delay: 500; target: *;">
        <div class="header-words uk-container center uk-text-center">
          <div class="header-text uk-text-center">
            <h1 class="uk-text-center">Blacksburg High School</h1>
            <h3 class="uk-text-center">Swimming and Diving</h3>
          </div>
        </div>
        <div class="call-to-action-container center">
          <a class="uk-icon-link animated bounce" (click)="learnMore()" uk-icon="icon: chevron-down; ratio: 4" style="color: white"></a>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="team">
  <div class="container information-section" uk-scrollspy="cls: uk-animation-fade; delya: 300; target: *">
    <h1 class="uk-text-center header-description">Our Team</h1>
    <hr class="uk-divider-icon" />
    <div class="uk-child-width-1-2@m uk-child-width-1-1@s uk-grid-match" uk-grid>
      <div id="swimmers">
        <div class="uk-card uk-card-default">
          <div class="uk-card-body">
            <h3 class="uk-card-title">Swimming</h3>
            <p>
              BHS swim and dive competes against 4A schools in Virginia. The swim team is coached by Chris Reilly who is starting his 6th
              year at the helm. Our goal is to provide a safe supportive environment to teach proper technique, sportsmanship
              and team unity. The season starts at the beginning of November and ends in mid February. We train at the Blacksburg
              Aquatic Center and War Memorial Pool on the Virginia Tech campus. We welcome swimmers with all abilities to
              be part of our program. If interested please contact Chris Reilly
              <a href="tel://540-250-2181" class="uk-button-text">540-250-2181</a>.
            </p>
          </div>
          <div class="uk-card-media-bottom">
            <img src="/assets/images/swim_pics/swimmers.jpg" alt="Swimmers" />
          </div>
        </div>
      </div>
      <div id="divers">
        <div class="uk-card uk-card-default">
          <div class="uk-card-media-top">
            <img src="/assets/images/swim_pics/diving.jpg" alt="Divers" />
          </div>
          <div class="uk-card-body">
            <h3 class="uk-card-title">Diving</h3>
            <p>
              The diving team is coached by Ron Piemonte who is starting his second year. Our dive team trains at the Christiansburg Aquatic
              Center and at War Memorial pool at the VT campus. Anyone with an interest in diving is welcome to join the
              program. People with a gymnastic background are strongly encouraged to give diving a chance. Anyone interested
              in trying out for the BHS dive team should contact Ron Piemonte
              <a class="uk-button-text" href="tel://540-641-4292">540-641-4292</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="quote-1">
  <div class="first-bg jarallax" data-jarallax="{&quot;speed&quot;: 0.2}">
    <div class="uk-container" uk-scrollspy="cls: uk-animation-fade; delay: 300; target: *">
      <p class="center-align uk-text-middle quote">
        “Competitive swimming is a fantastic mental and physical escape for me. I am so grateful for the sport that changed my life,
        and I encourage everyone to try it.”
      </p>
      <p class="center-align uk-text-middle author">~Sam Sterling, Senior~</p>
    </div>
  </div>
</section>
<section id="values">
  <div class="container" uk-scrollspy="cls: uk-animation-fade; delay: 300; target: *">
    <h1 class="header-description center-align">Our Values</h1>
    <hr class="uk-divider-icon" />
    <div class="uk-child-width-1-1@s uk-child-width-1-2@m uk-grid-match" uk-grid>
      <div class="uk-text-center values">
        <div>
          <div class="uk-card uk-card-default uk-card-body">
            <h3 class="uk-card-title">Sportsmanship</h3>
            <p>
              Win or lose, we will always conduct ourselves with respect. We realize we represent not only our school but also our town.
              We love Blacksburg and Blacksburg High School. We strive to set an exemplary of good sportsmanship in every
              competition.
            </p>
          </div>
        </div>
      </div>
      <div class="uk-text-center valign-wrapper">
        <img src="/assets/images/swim_pics/ryan.jpg" class="images" alt="Sportsmanship Image" />
      </div>
    </div>
    <div class="uk-child-width-1-1@s uk-child-width-1-2@m uk-grid-match" uk-grid>
      <div class="uk-text-center valign-wrapper">
        <img src="/assets/images/swim_pics/alice_and_anna.jpg" class="images-left" alt="Friendship Image" />
      </div>

      <div class="uk-text-center values-left">
        <div>
          <div class="uk-card uk-card-default uk-card-body">
            <h3 class="uk-card-title">Friendship</h3>
            <p>
              High school is a short four years where lifelong relationships can begin. Blacksburg is a special place where the pride of
              community spirit is valued. Through the sport of swimming and diving, competing as a Team representing Blacksburg
              High School we establish lasting friendships in a sport we can compete in long after high school. We will always
              strive to do our best, and support each other.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-child-width-1-1@s uk-child-width-1-2@m uk-grid-match" uk-grid>
      <div class="uk-text-center values">
        <div>
          <div class="uk-card uk-card-default uk-card-body">
            <h3 class="uk-card-title">Perseverance</h3>
            <p>
              Blacksburg has won state championships in many sports. We have never won a state championship in swimming. The last two years
              our girls have been second and third at state while the boys have been seventh and fifth. We constantly strive
              to improve. With commitment and perseverance, we hope to accomplish our goal of an elusive state title for
              the Swimming and Diving Team.
            </p>
          </div>
        </div>
      </div>
      <div class="uk-text-center valign-wrapper">
        <img src="/assets/images/swim_pics/perseverance_hailey.jpg" class="images" alt="Perseverance Images" />
      </div>
    </div>
  </div>
  <div class="uk-container" uk-scrollspy="cls: uk-animation-fade; delay: 300; target: *">
    <p class="uk-text-center description">
      Our team values friendship, hard work, and unity. Friendship is important for our team because we forge relationships that
      will last long after high school. Through hard work we will accomplish our goals. This season, we are moving to 4A,
      and we expect to be extremely competitive with both our Girls and Boys teams. Last year our Girls were third in the
      State and our Boys were fifth at the State Championships. Unity is essential for us to accomplish our goals. We understand
      that the TEAM need both swimmers and divers for us to be successful. It is with this sense of unity that we will be
      able to succeed with our team’s goals.
    </p>
  </div>
</section>
<section id="quote-2">
  <div class="second-bg jarallax" data-jarallax="{&quot;speed&quot;: 0.2}">
    <div class="uk-container" uk-scrollspy="cls: uk-animation-fade; delay: 300; target: *">
      <p class="center-align uk-text-middle quote">
        "Being part of the BHS swim team is so much fun. Everyone is so supportive, and I am proud to represent Blacksburg High School."
      </p>
      <p class="center-align uk-text-middle author">~Abigail Reilly, Junior~</p>
    </div>
  </div>
</section>
<div class="container row" id="our-job" uk-scrollspy="cls: uk-animation-fade; delay: 300; target: *">
  <h1 class="header-description center-align">Our Coaches</h1>
  <hr class="uk-divider-icon" />
  <div class="row">
    <div class="col s12 uk-text-center">
      <div class="uk-child-width-1-2@m" uk-grid>
        <div>
          <div class="uk-card uk-card-default">
            <div class="uk-card-media-top">
              <img src="/assets/images/swim_pics/chris.jpg" alt="Chris Reilly Coaching" />
            </div>
            <div class="uk-card-body">
              <h3 class="uk-card-title">Chris Reilly</h3>
              <p>Swim Coach</p>
              <p>
                Coach Reilly swam year round and for New Mexico State University. He has coached high school and year round swimming in Albuquerque
                New Mexico, age group swimming in Augusta, Georgia and masters swimming. He has had several top 16 age group
                swimmers and national champion finalists for various age groups. Coach Reilly teaches physiology at the medical
                school (VCOM) is married and has 4 children.
              </p>
            </div>
          </div>
        </div>
        <div>
          <div class="uk-card uk-card-default">
            <div class="uk-card-media-top">
              <img src="/assets/images/swim_pics/ronnie.jpg" alt="Ronnie Piemonte Coach" />
            </div>
            <div class="uk-card-body">
              <h3 class="uk-card-title">Ronnie Piemonte</h3>
              <p>Dive Coach</p>
              <p>
                Coach Piemonte comes with a long diving family history. Coach Piemonte was a diver for Christiansburg high school where he
                won the state championships 4 years in a row and holds the state record for diving. Ronnie coaches diving
                year round and is a student at Virginia tech. His Sister is a diver at Virginia tech and won state 4 years
                also and his mother and father coach diving. His father Ron is the Head diving coach for Virginia Tech.
              </p>
            </div>
          </div>
        </div>
        <div>
          <div class="uk-card uk-card-default">
            <div class="uk-card-media-top" id="coach-skinner">
              <img src="/assets/images/swim_pics/skinner.jpg" alt="Ned Skinner Coach" />
            </div>
            <div class="uk-card-body">
              <h3 class="uk-card-title">Ned Skinner</h3>
              <p>Swim Coach</p>
              <p>
                Coach Skinner is a 1989 graduate of LSU. He was captain of the 1988 LSU team that won the Southeastern Conference team title.
                He was also a coach for 5 other conference championship teams. Skinner received his Masters Degree from Ohio
                U. in 1990 and his Ph. D from Virginia Tech in 2004. Coach Skinner was named to the United States National
                team staff in 2016 and 2017 as he coached Robert Owen to a 5th place finish at the 2016 US Olympic Trials.
                Coach Skinner will be representing The USA as he travels to Thailand in January and February 2019 to work
                with the Thai National team and other youth swimmers. In his 28 years college swimming and diving Coach Skinner
                has mentored over 50 conference champions, and over 100 NCAA All-Americans. His teams were consistently named
                NCAA Academic All-America teams maintaining gpa's above 3.0.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<section id="quote-3">
  <div class="third-bg jarallax" data-jarallax="{&quot;speed&quot;: 0.2}">
    <div class="uk-container" uk-scrollspy="cls: uk-animation-fade; delay: 300; target: *">
      <p class="center-align uk-text-middle quote" id="kenzie">
        "I love swimming because I love the feeling that you get before a race, knowing that all the hours of training come together
        at the moment and that's when you've got to get up and give it your all"
      </p>
      <p class="center-align uk-text-middle author">
        ~Mackenzie Guillot, Senior~
      </p>
    </div>
  </div>
</section>
<section id="involve">
  <div class="container row center-align" uk-scrollspy="cls: uk-animation-fade; delay: 300; target: *">
    <h1 class="header-description center-align">Get Involved</h1>
    <div class="col s12 uk-text-center">
      <p>
        For more information about joining our team please contact Chris Reilly
        <a href="tel://540-250-2181" class="uk-button-text">540-250-2181</a>.
      </p>
      <br />
      <h5>
        <b>Blacksburg High School is accepting donations through Donately to pay for meet transportation, states and equipment!
          Donate now or check out our donation page!</b>
      </h5>
    </div>
    <div class="col s12 uk-text-center" id="donately">
      <iframe class="donately-donation-iframe" frameborder="0" allowtransparency="true" style="background-color: transparent; border: 0px none transparent; overflow: hidden; display: inline-block; visibility: visible; margin: 0px; padding: 0px; height: 800px; width: 100%;"
        src="https://cdn.donately.com/dntly-core/current/iframe.html?donately_id=act_bc3e733b407d&amp;stripe_publishable_key=pk_live_gqBNaMASHgYnmr4GSwivkRE0&amp;donately_amount=50&amp;donately_campaign_id=6010&amp;donately_comment=true&amp;donately_custom_css=%7B%20%22.donately-donation-form%20.donately-btn%22:%7B%22background-color%22:%22%232196f3%22%7D,%20%22.donately-donation-form%20.donately-btn:hover%22:%7B%22background-color%22:%22%231e87f0%22%7D%20%7D&amp;"></iframe>
    </div>
  </div>
</section>
<section id="end">
  <div class="hide-on-med-and-down container center-align" uk-scrollspy="cls: uk-animation-fade; delay: 500; target: *">
    <a (click)="toTop()" class="uk-text-center">Back to Top</a>
  </div>
  <div class="hide-on-large-only container center-align">
    <a (click)="toTop()" class="uk-text-center">Back to Top</a>
  </div>
</section>
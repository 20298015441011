import { DataService } from './../data.service';
import { SeoService } from './../seo.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.css']
})
export class ResultsComponent implements OnInit {
  numbers = [];
  results = [];
  constructor(public _data: DataService, private seo: SeoService) {}
  ngOnInit() {
    this.seo.generateTags({
      title: 'BHS Swim & Dive Results',
      description: `Our team results of our swim and dive meet results.`,
      image:
        'https://firebasestorage.googleapis.com/v0/b/bhs-swim-and-dive.appspot.com/o/seo%2Fcammie_laura.jpg?alt=media&token=9acbcca0-48f2-4dff-8155-096bcafc92fd',
      slug: '/results'
    });
    this._data.results.subscribe(val => {
      this.results = val;
    });
  }

  /**
   * Sends user to another page explaining more information about a meet
   * @param {any} meet more details about a particular meet
   * TODO: Implement with router and nested routing
   */
  moreDetails(meet: any): void {
    console.log(`Sending user to ${meet} for more information`);
  }
}

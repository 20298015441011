import {
  Component,
  HostListener,
  Inject,
  EventEmitter,
  AfterViewInit
} from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/platform-browser';
import { MaterializeAction } from 'angular2-materialize';
import { LoginService } from '../login.service';
import {
  PageScrollService,
  PageScrollInstance,
  PageScrollConfig
} from 'ngx-page-scroll';

declare const $: any;
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements AfterViewInit {
  changeStyle = false;
  isHomePage = true;
  modalActions = new EventEmitter<string | MaterializeAction>();
  constructor(
    @Inject(DOCUMENT) private _document: any,
    private _pageScrollService: PageScrollService,
    public _login: LoginService,
    private _route: Router
  ) {
    PageScrollConfig.defaultScrollOffset = 100;
    PageScrollConfig.defaultEasingLogic = {
      ease: (t: number, b: number, c: number, d: number): number => {
        // easeInOutExpo easing
        if (t === 0) {
          return b;
        }
        if (t === d) {
          return b + c;
        }
        if ((t /= d / 2) < 1) {
          return (c / 2) * Math.pow(2, 10 * (t - 1)) + b;
        }
        return (c / 2) * (-Math.pow(2, -10 * --t) + 2) + b;
      }
    };
    _route.events.subscribe((val: Event) => {
      if (val instanceof NavigationEnd) {
        this.changeStyle = val.url === '/' ? false : true;
        this.isHomePage = val.url === '/' ? true : false;
      }
    });
  }

  ngAfterViewInit() {
    $('.button-collapse').sideNav({
      closeOnClick: true,
      draggable: true,
      menuWidth: 100
    });
  }

  @HostListener('window:scroll', ['$event'])
  toggleNavbar(event): void {
    this._route.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.isHomePage = val.url === '/' ? true : false;
      }
    });
    let number =
      window.pageYOffset ||
      this._document.documentElement.scrollTop ||
      this._document.body.scrollTop;
    if (number > 500 && this.isHomePage) {
      this.changeStyle = true;
    } else if (this.changeStyle && number < 10 && this.isHomePage) {
      this.changeStyle = false;
    }
  }
  public goTo(id): void {
    let pageScrollInstance: PageScrollInstance = PageScrollInstance.simpleInstance(
      this._document,
      id
    );
    this._pageScrollService.start(pageScrollInstance);
  }
}

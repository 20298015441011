import { Component, OnInit } from '@angular/core';
declare const UIkit: any;
@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: [ './login-page.component.css' ]
})
export class LoginPageComponent implements OnInit {
  constructor() { }
  ngOnInit() { }
}

export class User {
  constructor(
    private _name: string,
    private _title: 'swimmer' | 'diver' | 'coach' | 'admin'
  ) {}

  get name(): string {
    return this._name;
  }
  get title(): 'swimmer' | 'diver' | 'coach' | 'admin' {
    return this._title;
  }

  get firebase() {
    return { name: this.name, title: this.title };
  }
}

/**
 * @name - LoginService
 * @author - Brandon Fan
 * @description - Login Service to handle authentication
 * @version - 1.4
 */
import { Injectable, Inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { User } from './types/user';
import { AngularFireDatabase } from '@angular/fire/database';
import { FirebaseApp } from '@angular/fire';
import { Roster } from './types/roster';
import { takeWhile } from 'rxjs/operators';


@Injectable()
export class LoginService {
  // initialize properties
  user: any = {};
  processed = false;
  isNewUser = false;
  isValid = false;
  isFirstTime = new BehaviorSubject(true);
  user_ref: any;
  profileData: any;
  isDefault = true;
  loggedIn = false;
  isPending = false;

  /**
   * Initializes Login Service and its required sources
   * @constructor
   * @param {AngularFireAuth} afAuth
   */
  constructor(
    @Inject(FirebaseApp) private firebaseApp: any,
    public afAuth: AngularFireAuth,
    public _db: AngularFireDatabase,
    public router: Router
  ) {
    this.checkAuthState();
  }

  /**
   * Logout user who is logged in
   */
  public logout(navigate = '/'): void {
    this.router.navigateByUrl(navigate);
    this.afAuth.auth.signOut();
  }

  /**
   * Checks whether a user is logged in
   */
  public checkAuthState(): void {
    this.isPending = true;
    this.afAuth.authState.subscribe(
      user => {
        this.isPending = false;
        if (user) {
          // user is logged in
          this.user = user;
          this.loggedIn = true;
          this.user_ref = this._db.object(`/users/${ this.getUserId() }`);
          console.log('logged in');
          this.checkIfFirstTime();
          this.router.navigateByUrl('/dashboard');
        } else {
          // user is not logged in
          console.log('not logged in');
          this.user = null;
          this.loggedIn = false;
        }
      },
      error => console.error(error)
    );
  }

  /**
   * Check if its first time user has logged in
   *
   */
  public checkIfFirstTime(): void {
    this._db
      .object('/users/' + this.getUserId())
      .valueChanges()
      .subscribe(val => {
        this.isFirstTime.next(
          val[ 'hadFirstTime' ] === undefined || val[ 'hadFirstTime' ] === null
            ? true
            : false
        );
      });
  }

  public getAccountByName(name: string): any {
    return this._db.list('/users', ref =>
      ref.orderByChild('name').equalTo(name)
    ).valueChanges();
  }

  public getUser(): Object {
    return this.user;
  }

  checkDefault() {
    return this.isDefault;
  }

  /**
   * Returns current user's photo url
   */
  public getUserPhoto(): string {
    this.user = this.afAuth.auth.currentUser;
    if (this.user) {
      let userPhoto = '';
      userPhoto = this.user.photoURL;
      if (userPhoto == null) {
        this.isDefault = true;
      } else {
        this.isDefault = false;
      }
      return userPhoto;
    } else {
      this.loggedIn = false;
      this.user = null;
      return null;
    }
  }
  /**
   * Return current user's id
   */
  public getUserId(): string {
    return this.user.uid;
  }
  public isLoggedIn(): boolean {
    return this.loggedIn;
  }

  public getNewUser(): boolean {
    return this.isNewUser;
  }

  public getUserData(): any {
    return this.user_ref.valueChanges();
  }

  public createUser(user: User) {
    this._db.object(`/users/${ this.getUserId() }`).update(user.firebase);
    this._db
      .object(`/users/${ this.getUserId() }`)
      .update({ hadFirstTime: true, isNewUser: false });
  }
  public updateUser(profile: any) {
    this._db.object(`/users/${ this.getUserId() }/profile`).update(profile);
  }
}

<!--
  Edited on July 28th, 2017
  Author: Brandon Fan
  Version: 0.0.1-alpha
-->

<!-- TODO: Need to determine event requirements -->
<section id="events">
  <div class="uk-container">
    <h1 class="heading">Our Events</h1>
    <ul
      class="uk-flex-center"
      uk-tab
      uk-switcher="animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium"
    >
      <li><a href="#">Event View</a></li>
      <li><a href="#">Calendar View</a></li>
    </ul>
    <ul class="uk-switcher uk-margin">
      <li id="event-view">
        <p *ngIf="_data.eventsLength <= 0">
          There are no events at this time, check back soon for updates!
        </p>
        <div
          class="uk-child-width-1-2@s uk-child-width-1-3@m uk-text-center"
          uk-grid
          uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 300"
        >
          <div *ngFor="let event of events">
            <div class="uk-card uk-card-default">
              <div class="uk-card-body">
                <div
                  class="uk-card-badge uk-label"
                  [class.fundraiser]="
                    event.type === 'fundraiser' || event.type === 'special'
                  "
                  [class.practice]="
                    event.type === 'practice' ||
                    event.type === 'Swim Practice' ||
                    event.type === 'Dive Practice'
                  "
                  [class.optional]="event.type === 'optional'"
                >
                  {{ event.type }}
                </div>
                <h5 class="event-name">{{ event.name }}</h5>
                <p class="date">
                  Date From: {{ event.startDate }}-{{ event.endDate }}
                </p>
                <p class="time">
                  Time: {{ event.startTime }}-{{ event.endTime }}
                </p>
                <p>
                  <span>{{ event.description }}</span>
                </p>
                <a
                  target="_blank"
                  (click)="emitEvent(event.type, event.address)"
                  [href]="
                    'https://www.google.com/maps/dir/?api=1&q=' + event.address
                  "
                  class="uk-button-text uk-text-center direction-link"
                  >Get Directions</a
                >
              </div>
              <div class="uk-card-media-bottom uk-align">
                <iframe
                  width="100%"
                  height="100%"
                  frameborder="0"
                  style="border:0"
                  [src]="event['url']"
                  allowfullscreen
                >
                </iframe>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="calendar-view">
        <iframe
          class="uk-align-center"
          src="https://calendar.google.com/calendar/embed?title=Blacksburg%20Swim%20and%20Dive%20Calendar&amp;height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FNew_York"
          style="border-width:0"
          width="800"
          height="600"
          frameborder="0"
          scrolling="no"
        ></iframe>
        <p class="uk-text-center">
          Can't see it? Access it
          <a
            href="https://calendar.google.com/calendar/embed?src=blacksburgswimanddive%40gmail.com&ctz=America/New_York
                    "
            >here</a
          >
        </p>
      </li>
    </ul>
  </div>
</section>

<section id="loader" *ngIf="!showData" class="center-align" [class.uk-animation-fade.uk-animation-reverse]="showData">
    <app-preloader [load]="!showData"></app-preloader>
    <h3 class="uk-text-center uk-animation-fade">Loading Profile and Stats</h3>
</section>
<section id="dashboard" *ngIf="(authenticated || !_login.isFirstTime) && showData">
    <div id="jumbotron">
        <div id="particles-js" class="boys-swim"></div>
        <div id="header">
            <div class="container" id="dashboard-title" uk-scrollspy="cls: uk-animation-fade; target: > *; delay: 500">
                <div class="uk-text-center">
                    <span id="profile-default" uk-icon="icon: user; ratio: 8.75" style="color: white" class="profile uk-align-center" *ngIf="_login.isPending === false && _login.checkDefault()"></span>
                </div>
                <img class="profile uk-align-center" *ngIf="_login.isPending === false && !_login.checkDefault()" src="{{_login.getUserPhoto() + '?sz=175'}}"
                    alt="profile">
                <h1 class="uk-text-center" *ngIf="_login.isPending === false">Hey {{name}}!</h1>
                <p id="tagline" class="uk-text-center" *ngIf="_login.isPending === false && title !== undefined && title !== null && title !== '' && title !== 'admin'">~ A {{capitalizeFirstLetter(title)}}~</p>
                <p id="tagline" class="uk-text-center" *ngIf="_login.isPending === false && title !== undefined && title !== null && title !== '' && title === 'admin'">~ An {{capitalizeFirstLetter(title)}}~</p>
            </div>
        </div>
    </div>
    <div class="content">
        <div class="uk-container main-dashboard">
            <ul class="uk-flex-center" uk-tab uk-switcher="animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium">
                <li>
                    <a href="#">Events</a>
                </li>
                <li>
                    <a href="#" (click)="eventModal = false;">Roster</a>
                </li>
                <li>
                    <a href="#" (click)="eventModal = false;">Results</a>
                </li>
            </ul>

            <ul class="uk-switcher uk-margin">
                <li id="event-list">
                    <div class="events" uk-grid uk-scrollspy="cls: uk-animation-fade; target: > div; delay: 300" *ngIf="!eventModal">
                        <div class="uk-width-1-1@s">
                            <h3 class="uk-text-center">Events</h3>
                            <hr class="uk-divider-icon">
                            <button (click)="showModal('#add-event');" class="uk-align-center uk-button uk-button-primary">Add Event</button>
                        </div>
                        <div class="events-list uk-width-expand@l">
                            <div class="uk-text-center">
                                <h5 *ngIf="_data.eventsLength == 0">There are no events at the moment.
                                    <a (click)="showModal('#add-event'); setAutocompleteGeo();" class="uk-button-text uk-text-center">Add one now!</a>
                                </h5>
                            </div>
                            <div *ngIf="_data.eventsLength != 0" class="uk-card uk-card-default uk-card-body">
                                <ul uk-accordion>
                                    <li *ngFor="let event of events; let i = index" [class.uk-open]="i == 0">
                                        <h3 class="uk-accordion-title">{{event.name}}</h3>
                                        <div class="uk-accordion-content">
                                            <p class="type">{{capitalizeFirstLetter(event.type)}}</p>
                                            <p class="location">{{event.address}}</p>
                                            <p class="date">Date From: {{event.startDate}}-{{event.endDate}}</p>
                                            <p class="time">Time: {{event.startTime}}-{{event.endTime}}</p>
                                            <p>{{event.description}}
                                            </p>
                                            <a class="uk-button-text" style="margin-right: 10px;" (click)="showModal('#add-event', true, event)">Edit Event</a>
                                            <a class="uk-button-text" (click)="deleteEvent(event)">Delete Event</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li id="roster-list">
                    <div class="roster" uk-grid uk-scrollspy="cls: uk-animation-fade; target: > div; delay: 300">
                        <div class="uk-width-1-1@s">
                            <h3 class="uk-text-center">Roster</h3>
                            <hr class="uk-divider-icon">
                        </div>
                        <div class="swimmers-list uk-width-1-2@l">
                            <div class="uk-card uk-card-default uk-card-body">
                                <h5 class="uk-text-center">Swimmers</h5>
                                <button (click)="showModal('#add-roster'); individual.title = 'swimmer'" class="uk-align-center uk-button uk-button-primary">Add Swimmer</button>
                                <p class="uk-text-center" *ngIf="swimmers.length == 0">Looks like you have no swimmers,
                                    <a class="uk-button-text" (click)="showModal('#add-roster'); individual.title ='swimmer'">add one now!</a>
                                </p>
                                <ul uk-accordion *ngIf="swimmers.length > 0">
                                    <li *ngFor="let swimmer of swimmers; let i = index;" [class.uk-open]="i == 0">
                                        <h3 class="uk-accordion-title">{{swimmer.name}}</h3>
                                        <div class="uk-accordion-content">
                                            <ul>
                                                <li>Gender: {{swimmer.gender}}</li>
                                                <li>Grade: {{capitalizeFirstLetter(swimmer.grade)}}</li>
                                            </ul>
                                            <a class="uk-button-text" style="margin-right: 10px;" (click)="showModal('#add-roster', true, swimmer)">Edit Individual</a>
                                            <a class="uk-button-text" style="margin-right: 10px;" (click)="gradeUp(swimmer)">Grade Up Individual</a>
                                            <a class="uk-button-text" (click)="deleteIndividual(swimmer)">Delete Individual</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="divers-list uk-width-1-2@l">
                            <div class="uk-card uk-card-default uk-card-body">
                                <h5 class="uk-text-center">Divers</h5>
                                <button (click)="showModal('#add-roster'); individual.title = 'diver'" class="uk-align-center uk-button uk-button-primary">Add Diver</button>
                                <p class="uk-text-center" *ngIf="divers.length == 0">Looks like you have no divers,
                                    <a class="uk-button-text" (click)="showModal('#add-roster'); individual.title ='diver'">add one now!</a>
                                </p>
                                <ul uk-accordion>
                                    <li *ngFor="let diver of divers; let i = index" [class.uk-open]="i == 0">
                                        <h3 class="uk-accordion-title">{{diver.name}}</h3>
                                        <div class="uk-accordion-content">
                                            <ul>
                                                <li>Gender: {{diver.gender}}</li>
                                                <li>Grade: {{capitalizeFirstLetter(diver.grade)}}</li>
                                            </ul>
                                            <a class="uk-button-text" style="margin-right: 10px;" (click)="showModal('#add-roster', true, diver)">Edit Individual</a>
                                            <a class="uk-button-text" style="margin-right: 10px;" (click)="gradeUp(diver)">Grade Up Individual</a>
                                            <a class="uk-button-text" (click)="deleteIndividual(diver)">Delete Individual</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="coach-list uk-width-1-2@l">
                            <div class="uk-card uk-card-default uk-card-body">
                                <h5 class="uk-text-center">Coaches</h5>
                                <button (click)="showModal('#add-roster'); individual.title = 'coach'" class="uk-align-center uk-button uk-button-primary">Add Coach</button>
                                <p class="uk-text-center" *ngIf="divers.length == 0">Looks like you don't have any coaches,
                                    <a class="uk-button-text" (click)="showModal('#add-roster'); individual.title ='coach'">add one now!</a>
                                </p>
                                <ul uk-accordion>
                                    <li *ngFor="let coach of coaches; let i = index" [class.uk-open]="i == 0">
                                        <h3 class="uk-accordion-title">{{coach.name}}</h3>
                                        <div class="uk-accordion-content">
                                            <ul>
                                                <li>Gender: {{coach.gender}}</li>
                                                <li>Title: {{capitalizeFirstLetter(coach.title)}}</li>
                                            </ul>
                                            <a class="uk-button-text" style="margin-right: 10px;" (click)="showModal('#add-roster', true, coach)">Edit Individual</a>
                                            <a class="uk-button-text" (click)="deleteIndividual(coach)">Delete Individual</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="admin-list uk-width-1-2@l" *ngIf="title === 'admin'">
                            <div class="uk-card uk-card-default uk-card-body">
                                <h5 class="uk-text-center">Administrators</h5>
                                <button (click)="showModal('#add-roster'); individual.title = 'admin'" class="uk-align-center uk-button uk-button-primary">Add Administrator</button>
                                <p class="uk-text-center" *ngIf="admins.length == 0">Looks like you don't have any administrators,
                                    <a class="uk-button-text" (click)="showModal('#add-roster'); individual.title ='admin'">add one now!</a>
                                </p>
                                <ul uk-accordion>
                                    <li *ngFor="let admin of admins; let i = index" [class.uk-open]="i == 0">
                                        <h3 class="uk-accordion-title">{{admin.name}}</h3>
                                        <div class="uk-accordion-content">
                                            <ul>
                                                <li>Gender: {{admin.gender}}</li>
                                                <li>Title: {{capitalizeFirstLetter(admin.title)}}</li>
                                            </ul>
                                            <a class="uk-button-text" style="margin-right: 10px;" (click)="showModal('#add-roster', true, admin)">Edit Individual</a>
                                            <a class="uk-button-text" (click)="deleteIndividual(admin)">Delete Individual</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li id="meet-results">
                    <div class="results" uk-grid uk-scrollspy="cls: uk-animation-fade; target: > div; delay: 300" *ngIf="!resultModal">
                        <div class="uk-width-1-1@s">
                            <h3 class="uk-text-center">Results</h3>
                            <hr class="uk-divider-icon">
                            <button (click)="showModal('#add-result');" class="uk-align-center uk-button uk-button-primary">Add Meet Result</button>
                        </div>
                        <div class="events-list uk-width-expand@l">
                            <div class="uk-text-center">
                                <h5 *ngIf="_data.resultsLength == 0">There are no meet results at the moment.
                                    <a (click)="showModal('#add-result'); setAutocompleteGeo();" class="uk-button-text uk-text-center">Add one now!</a>
                                </h5>
                            </div>
                            <div *ngIf="_data.resultsLength != 0" class="uk-card uk-card-default uk-card-body">
                                <ul uk-accordion>
                                    <li *ngFor="let result of results; let i = index" [class.uk-open]="i == 0">
                                        <h3 class="uk-accordion-title">{{result.name}}</h3>
                                        <div class="uk-accordion-content">
                                            <p>{{result.description}}
                                            </p>
                                            <a class="uk-button-text" style="margin-right: 10px;" (click)="showModal('#add-result', true, result)">Edit Meet</a>
                                            <a class="uk-button-text" (click)="deleteResult(result)">Delete Meet</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</section>

<section id="new-user" *ngIf="(isFirstTime || !authenticated) && showData">
    <form #newUser="ngForm" class="uk-align-center uk-container">
        <p *ngIf="start" [class.uk-animation-fade]="!start" class="uk-text-center">
            Who are you?
        </p>
        <select [(ngModel)]="title" class="uk-select" [class.uk-animation-fade]="start" name="title" id="title" *ngIf="start" required>
            <option value="coach">A Coach</option>
            <option value="admin">An Admin</option>
        </select>
        <p *ngIf="!start" [class.uk-animation-fade]="!start" class="uk-text-center">What's your name?</p>
        <p class="subtitle uk-text-center" *ngIf="!start" [class.uk-animation-fade]="!start">Don't see your name? Contact Chris Reilly, Brandon Fan or Traci Sterling</p>
        <select class="uk-select" [(ngModel)]="name" name="names" [class.uk-animation-fade]="!start" *ngIf="!start" required>
            <option *ngFor="let value of name_options" [ngValue]="value">{{value}}</option>
        </select>
        <button (click)="newUserNext()" class="uk-button uk-button-primary uk-align-center" *ngIf="start" [class.uk-animation-fade]="start"
            [disabled]="newUser.invalid">Continue</button>
        <button (click)="newUserFinish()" class="uk-button uk-button-primary uk-align-center" *ngIf="!start" [class.uk-animation-fade]="!start"
            [disabled]="newUser.invalid">Finish</button>
        <a (click)="newUserBack()" *ngIf="!start" [class.uk-animation-fade]="!start" class="uk-button-text back">Go Back</a>
    </form>
</section>

<div id="already-created" uk-modal>
    <div class="uk-modal-dialog uk-modal-body">
        <h2 class="uk-modal-title">{{createdText}}</h2>
        <p>An account under {{name}} has already been created.</p>
        <p class="uk-text-right">
            <button class="uk-button uk-button-default uk-modal-close" type="button">Okay</button>
        </p>
    </div>
</div>
<div id="add-event" [class.block]="eventModal" [class.uk-open]="eventModal" [class.none]="!eventModal" [class.uk-animation-slide-bottom-medium]="eventModal">
    <div class="uk-modal-dialog">
        <button (click)="hideModal('#add-event'); resetEvent(); eventCreator.reset();" class="uk-modal-close-default" type="button"
            uk-close></button>
        <div class="uk-modal-header">
            <h2 class="uk-modal-title" *ngIf="!isEditing">Add Event</h2>
            <h2 class="uk-modal-title" *ngIf="isEditing">Edit Event</h2>
            <p class="label">All Fields but Description are Required</p>
        </div>
        <form (ngSubmit)="addEvent(); hideModal('#add-event'); eventCreator.reset(); " #eventCreator="ngForm">
            <div class="uk-modal-body uk-container" uk-grid>
                <div class="uk-width-1-1">
                    <label for="event-name">Name of Event</label>
                    <input required type="text" placeholder="Enter Name of Event" [(ngModel)]="event.name" name="event-name" class="uk-input">
                </div>
                <br>
                <div class="uk-width-1-1">
                    <label for="event-type">Event Type</label>
                    <select required class="uk-select" [(ngModel)]="event.type" name="event-type" id="event-type">
                        <option value="meet">Meet</option>
                        <option value="Swim Meet">Swimming Meet Only</option>
                        <option value="Dive Meet">Diving Meet Only</option>
                        <option value="fundraiser">Fundraiser</option>
                        <option value="practice">Practice</option>
                        <option value="Swim Practice">Swim Practice</option>
                        <option value="Dive Practice">Dive Practice</option>
                        <option value="special">Special Event</option>
                        <option value="optional">Optional</option>
                    </select>
                </div>

                <div class="uk-width-1-1">
                    <label for="event-name">Event Address</label>
                    <input type="text" #eventAddress placeholder="Enter Address of Event" id="address" [(ngModel)]="event.address" name="event-address"
                        class="uk-input">
                </div>
                <div class="uk-width-1-2">
                    <label for="start-date">Start Date</label>
                    <input placeholder="Enter Start Date" type="text" id="start-date" class="datepicker">
                </div>
                <div class="uk-width-1-2">
                    <label for="end-date">End Date</label>
                    <input placeholder="Enter End Date" type="text" id="end-date" class="datepicker">
                </div>
                <div class="uk-width-1-2">
                    <label for="start-time">Start Time</label>
                    <input placeholder="Enter Start Time" type="text" name="start-time" id="start-time" class="timepicker">
                </div>
                <div class="uk-width-1-2">
                    <label for="event-name">End Time</label>
                    <input placeholder="Enter End Time" type="text" name="end-time" id="end-time" class="timepicker">
                </div>
                <div class="uk-width-1-1">
                    <label for="description">Description</label>
                    <textarea name="description" [(ngModel)]="event.description" placeholder="Description of Event" cols="5" rows="15" class="uk-textarea description"></textarea>
                </div>
            </div>
            <div class="uk-modal-footer uk-text-right">
                <button class="uk-button uk-button-default" (click)="hideModal('#add-event'); resetEvent(); eventCreator.reset();" type="button">Cancel</button>
                <button *ngIf="!isEditing" class="uk-button uk-button-primary save" type="submit" [disabled]="eventCreator.invalid">Add Event</button>
                <button *ngIf="isEditing" class="uk-button uk-button-primary save" type="submit" [disabled]="eventCreator.invalid">Update Event</button>
            </div>
        </form>
    </div>
</div>
<div id="add-result" uk-modal>
    <div class="uk-modal-dialog">
        <button (click)="hideModal('#add-result'); resultCreator.reset()" class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-modal-header">
            <h2 class="uk-modal-title" *ngIf="!isEditing">Add Result</h2>
            <h2 class="uk-modal-title" *ngIf="isEditing">Edit Result</h2>
        </div>
        <form (ngSubmit)="addResult(); hideModal('#add-result'); resultCreator.reset(); " #resultCreator="ngForm">
            <div class="uk-modal-body uk-container" uk-grid>
                <div class="uk-width-1-1">
                    <label for="event-name">Name of Event</label>
                    <input required type="text" placeholder="Enter Name of Meet" [(ngModel)]="result.name" name="result-name" class="uk-input">
                </div>
                <br>
                <div class="uk-width-1-1">
                    <label for="description">Description</label>
                    <textarea name="description" [(ngModel)]="result.description" placeholder="Description of Meet" cols="5" rows="15" class="uk-textarea description"></textarea>
                </div>
                <div class="uk-width-1-1">
                    <p class="uk-text-center" *ngIf="result.imageUrl !== '' && noFile">Already has image</p>
                    <div class="uk-placeholder uk-text-center uk-align-center" *ngIf="!uploading">
                        <span uk-icon="icon: cloud-upload"></span>
                        <span class="uk-text-middle" *ngIf="noFile"> Upload a new photo by dropping them here or</span>
                        <span class="uk-text-middle" *ngIf="!noFile"> Use {{file.name}}?</span>
                        <div uk-form-custom>
                            <input type="file" (change)="onUpload($event)" accept=".jpg, .png, .gif">
                            <span class="uk-link" *ngIf="noFile"> selecting one</span>
                            <span class="uk-link" *ngIf="!noFile"> or reselect</span>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-1">
                    <p class="uk-text-center" *ngIf="result.pdfResults !== ''">Already has a file of results</p>
                    <div class="uk-placeholder uk-text-center uk-align-center" *ngIf="!uploadingPDF">
                        <span uk-icon="icon: cloud-upload"></span>
                        <span class="uk-text-middle" *ngIf="noFilePDF"> Upload a new result file by dropping one here or</span>
                        <span class="uk-text-middle" *ngIf="!noFilePDF"> Use {{pdfFile.name}}?</span>
                        <div uk-form-custom>
                            <input type="file" (change)="onUpload($event, true)" accept=".pdf, .docx, .doc">
                            <span class="uk-link" *ngIf="noFilePDF"> selecting one</span>
                            <span class="uk-link" *ngIf="!noFilePDF"> or reselect</span>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-1">
                    <a *ngIf="!noFile && !uploading" (click)="uploadImage(file, result, false)" class="uk-align-center uk-button uk-button-primary">Upload Photo</a>
                    <a *ngIf="!noFilePDF && !uploadingPDF" (click)="uploadResults(pdfFile, result)" class="uk-align-center uk-button uk-button-primary">Upload Result</a>
                    <p *ngIf="showProgress">Image Upload: </p>
                    <progress #upload class="uk-progress" [value]="progress" max="100" [class.hidden]="!showProgress" [class.uk-animation-fade]="uploading"></progress>
                    <hr>
                    <p *ngIf="showProgressPdf">PDF Upload: </p>
                    <progress #pdfUpload class="uk-progress" [value]="progressPDF" max="100" [class.hidden]="!showProgressPdf" [class.uk-animation-fade]="uploadingPDF"></progress>
                </div>
            </div>
            <div class="uk-modal-footer uk-text-right">
                <button class="uk-button uk-button-default" (click)="hideModal('#add-result'); resetResult();  resultCreator.reset();" type="button">Cancel</button>
                <button *ngIf="!isEditing" class="uk-button uk-button-primary save" type="submit" [disabled]="resultCreator.invalid">Add Meet Result</button>
                <button *ngIf="isEditing" class="uk-button uk-button-primary save" type="submit" [disabled]="resultCreator.invalid">Update Meet Result</button>
            </div>
        </form>
    </div>
</div>
<div id="add-roster" uk-modal="center: true">
    <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" (click)="resetIndividual(); rosterCreator.reset();" type="button" uk-close></button>
        <div class="uk-modal-header">
            <h2 *ngIf="!isEditing" class="uk-modal-title">Add Individual</h2>
            <h2 *ngIf="isEditing" class="uk-modal-title">Edit Individual</h2>
            <p class="label">All Fields are Required</p>
        </div>
        <form #rosterCreator="ngForm" (ngSubmit)="addIndividual(); hideModal('#add-roster'); rosterCreator.reset(); ">
            <div class="uk-modal-body uk-container" uk-grid>
                <div class="uk-width-1-1">
                    <label for="individual-name">Name of Individual</label>
                    <input required type="text" placeholder="Enter Name of Individual" [(ngModel)]="individual.name" name="individual-name" class="uk-input">
                </div>
                <div class="uk-width-1-1">
                    <label for="individual-title">Title of Individual</label>
                    <select required class="uk-select" [(ngModel)]="individual.title" name="individual-type" id="individual-type">
                        <option value="swimmer">Swimmer</option>
                        <option value="diver">Diver</option>
                        <option value="coach">Coach</option>
                        <option value="admin">Administrator</option>
                    </select>
                </div>
                <div class="uk-width-1-1" *ngIf="individual.title === 'swimmer' || individual.title === 'diver'">
                    <p class="label">Grade</p>
                    <select required class="uk-select" [(ngModel)]="individual.grade" name="individual-grade" id="individual-grade">
                        <option value="freshman">Freshman</option>
                        <option value="sophomore">Sophomore</option>
                        <option value="junior">Junior</option>
                        <option value="senior">Senior</option>
                    </select>
                </div>
                <div class="uk-width-1-2">
                    <label for="individual-gender">Gender</label>
                    <select *ngIf="individual.title === 'swimmer' || individual.title === 'diver'" required class="uk-select" [(ngModel)]="individual.gender"
                        name="individual-gender">
                        <option value="Boy">Boy</option>
                        <option value="Girl">Girl</option>
                    </select>
                    <select *ngIf="individual.title === 'coach' || individual.title === 'admin'" required class="uk-select" [(ngModel)]="individual.gender"
                        name="individual-gender">
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                    </select>
                </div>
                <div class="uk-width-1-1">
                    <p class="uk-text-center" *ngIf="individual.imageUrl !== '' && noFile && !uploading">Already has image</p>
                    <div class="uk-placeholder uk-text-center uk-align-center" *ngIf="!uploading">
                        <span uk-icon="icon: cloud-upload"></span>
                        <span class="uk-text-middle" *ngIf="noFile"> Upload a new photo by dropping them here or</span>
                        <span class="uk-text-middle" *ngIf="!noFile"> Use {{file.name}}?</span>
                        <div uk-form-custom>
                            <input type="file" (change)="onUpload($event)" accept=".jpg, .png, .gif">
                            <span class="uk-link" *ngIf="noFile"> selecting one</span>
                            <span class="uk-link" *ngIf="!noFile"> or reselect</span>
                        </div>
                    </div>
                </div>
                <div class="uk-width-1-1">
                    <a *ngIf="!noFile && !uploading" (click)="uploadImage(file, individual)" class="uk-align-center uk-button uk-button-primary">Upload Photo</a>
                    <p *ngIf="progress == 100">Image Successfully Uploaded</p>
                    <progress #upload class="uk-progress" [value]="progress" max="100" [class.hidden]="!showProgress"></progress>
                </div>
            </div>
            <div class="uk-modal-footer uk-text-right">
                <button class="uk-button uk-button-default uk-modal-close" *ngIf="!isEditing" type="button" (click)="resetIndividual(); rosterCreator.reset(); hideModal('#add-roster');">Cancel</button>
                <button class="uk-button uk-button-default uk-modal-close" *ngIf="isEditing" type="button" (click)="rosterCreator.reset(); hideModal('#add-roster');">Cancel</button>
                <button class="uk-button uk-button-primary save" type="submit" [disabled]="rosterCreator.invalid" *ngIf="!isEditing">Add Individual</button>
                <button class="uk-button uk-button-primary save" type="submit" [disabled]="rosterCreator.invalid" *ngIf="isEditing">Edit Individual</button>
            </div>
        </form>
    </div>
</div>
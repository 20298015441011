var User = /** @class */ (function () {
    function User(_name, _title) {
        this._name = _name;
        this._title = _title;
    }
    Object.defineProperty(User.prototype, "name", {
        get: function () {
            return this._name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(User.prototype, "title", {
        get: function () {
            return this._title;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(User.prototype, "firebase", {
        get: function () {
            return { name: this.name, title: this.title };
        },
        enumerable: true,
        configurable: true
    });
    return User;
}());
export { User };

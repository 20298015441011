/**
 * App Module
 * @author: Brandon Fan
 * @version: 0.0.1-alpha
 * Last Edited: July 28th
 */

// Import necessary modules for angular application
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule, Routes } from '@angular/router';
import { NgDateRangePickerModule } from 'ng-daterangepicker';
import { MaterializeModule } from 'angular2-materialize';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import * as firebase from 'firebase/app';
import * as firebaseui from 'firebaseui';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { FirebaseUIModule } from 'firebaseui-angular';

import { AngularFireAuthModule } from '@angular/fire/auth';
import { ChartsModule } from 'ng2-charts';
import { GoogleAnalyticsEventsService } from './ga.service';
import { environment } from './../environments/environment.prod';

// Component imports
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { PreloaderComponent } from './preloader/preloader.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { EventsComponent } from './events/events.component';
import { RosterComponent } from './roster/roster.component';
import { ResultsComponent } from './results/results.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { DashboardComponent } from './dashboard/dashboard.component';

// Provider imports
import { DataService } from './data.service';
import { LoginService } from './login.service';
import { SeoService } from './seo.service';
import { AngularFireStorageModule } from '@angular/fire/storage';
firebase.initializeApp(environment.firebase);
const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'roster', component: RosterComponent },
  { path: 'events', component: EventsComponent },
  { path: 'results', component: ResultsComponent },
  { path: 'login', component: LoginPageComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: '**', component: HomeComponent }
];

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'redirect',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ],
  tosUrl: '/tos'
};

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    PreloaderComponent,
    FooterComponent,
    EventsComponent,
    RosterComponent,
    ResultsComponent,
    LoginPageComponent,
    DashboardComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'my-app' }),
    MaterializeModule,
    FormsModule,
    HttpModule,
    ChartsModule,
    NgDateRangePickerModule,
    AngularFireModule.initializeApp(environment.firebase, 'bhs-swim-and-dive'),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    BrowserAnimationsModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    RouterModule.forRoot(appRoutes),
    NgxPageScrollModule
  ],
  providers: [
    DataService,
    LoginService,
    SeoService,
    GoogleAnalyticsEventsService
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }

export class Results {
  constructor(
    private _name: string,
    private _description: string,
    private _pdfResults?: string,
    private _imageUrl?: string
  ) {}
  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }
  get description() {
    return this._description;
  }
  set description(value) {
    this._description = value;
  }

  get pdfResults() {
    if (this._pdfResults !== undefined) {
      return this._pdfResults;
    }
    return '';
  }
  set pdfResults(value) {
    this._pdfResults = value;
  }
  get imageUrl(): string {
    if (this._imageUrl !== undefined) {
      return this._imageUrl;
    }
    return '';
  }

  set imageUrl(value: string) {
    this._imageUrl = value;
  }

  toFirebase() {
    return {
      name: this.name,
      description: this.description,
      pdfResults: this.pdfResults,
      imageUrl: this.imageUrl
    };
  }
}

<footer class="page-footer hide-on-med-and-down" uk-scrollspy="cls: uk-animation-fade; target: *; delay: 300;">
    <div class="uk-container uk-text-center">
        <hr>
        <p class="uk-text-center copyright">© 2017 BHS Swim and Dive</p>
        <p>Developed by Brandon Fan</p>
    </div>
</footer>
<footer class="page-footer hide-on-large-only">
    <div class="uk-container uk-text-center">
        <hr>
        <p class="uk-text-center copyright">© 2017 BHS Swim and Dive</p>
        <p>Developed by Brandon Fan</p>
    </div>
</footer>
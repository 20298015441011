export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyAKmJvE-NeGr_FxQhjogg95nY16wje1kiI',
    authDomain: 'bhs-swim-and-dive.firebaseapp.com',
    databaseURL: 'https://bhs-swim-and-dive.firebaseio.com',
    projectId: 'bhs-swim-and-dive',
    storageBucket: 'bhs-swim-and-dive.appspot.com',
    messagingSenderId: '922548212113'
  }
};

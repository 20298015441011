<!-- Edited on July 28th, 2017 
Author: Brandon Fan
Version: 0.0.1-alpha -->

<!-- TODO: Need to if current information is enough -->
<section id="roster">
    <div class="uk-container">
        <h1 class="heading">Our Roster</h1>
        <br>
        <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
            Filters: 
            <label><input #divers class="uk-checkbox" [(ngModel)]="showDivers" type="checkbox" (change)="showChange()"> Divers</label>
            <label><input #swimmers class="uk-checkbox" [(ngModel)]="showSwimmers" type="checkbox" (change)="showChange()"> Swimmers</label>
        </div>
        <br>
        <br>
        <br>
        <div class="uk-child-width-1-2@s uk-child-width-1-3@m uk-text-center" uk-grid uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 300">
            <div *ngFor="let individual of displayRoster">
                <div class="uk-card uk-card-default">
                    <div class="uk-card-media-top">
                        <img *ngIf="individual['imageUrl'] !== ''" [src]="individual['imageUrl']">
                    </div>
                    <div class="uk-card-body">
                        <h4>{{individual['name']}}</h4>
                        <p>{{capitalize(individual['title'])}}</p>
                        <p>{{capitalize(individual['grade'])}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
import { GoogleAnalyticsEventsService } from './../ga.service';
import { Component, OnInit } from '@angular/core';
import { DataService } from './../data.service';
import { SeoService } from './../seo.service';
import { EventFront } from '../types/eventfront';
import {
  DomSanitizer,
  SafeUrl,
  SafeResourceUrl
} from '@angular/platform-browser';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {
  events: EventFront[] = [];
  names = [];
  constructor(
    public _data: DataService,
    private _gaService: GoogleAnalyticsEventsService,
    private _sanitizer: DomSanitizer,
    private seo: SeoService
  ) {}
  ngOnInit() {
    this.seo.generateTags({
      title: 'BHS Swim & Dive Events',
      description: `Our team events page keeps track of fundraisers, meets and practices.`,
      image:
        'https://firebasestorage.googleapis.com/v0/b/bhs-swim-and-dive.appspot.com/o/seo%2Fdiving.jpg?alt=media&token=79569c75-ce26-4b83-aad5-ec93c23b05cf',
      slug: '/events'
    });
    this._data.events.subscribe(val => {
      val.forEach(el => {
        el['url'] = this.getGoogleMapsUrl(
          'https://www.google.com/maps/embed/v1/place?key=AIzaSyCJ0nxyd7NAU6RwDbRi2rMZmQKb3hLVPRQ&q=' +
            el.address
        );
        this.events.push(el);
      });
      this.events = this.events.sort((a: EventFront, b: EventFront) => {
        const aDate = new Date(`${a.startDate} ${a.startTime}`);
        const bDate = new Date(`${b.startDate} ${b.startTime}`);
        return aDate.getTime() - bDate.getTime();
      });
    });
  }
  getGoogleMapsUrl(url): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  emitEvent(type: string, value: string) {
    this._gaService.emitEvent('Direction link', 'Redirected', type);
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./results.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./results.component";
import * as i4 from "../data.service";
import * as i5 from "../seo.service";
var styles_ResultsComponent = [i0.styles];
var RenderType_ResultsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ResultsComponent, data: {} });
export { RenderType_ResultsComponent as RenderType_ResultsComponent };
function View_ResultsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" There are no meet results at this time, check back soon for updates! "]))], null, null); }
function View_ResultsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "uk-button-text"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Show Results"]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.pdfResults; _ck(_v, 0, 0, currVal_0); }); }
function View_ResultsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-margin"], ["uk-grid", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "uk-card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [["class", "uk-card-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "p", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResultsComponent_4)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "uk-card-media-right uk-cover-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "img", [["uk-cover", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "canvas", [["height", "400"], ["width", "600"]], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = (_v.parent.context.$implicit.pdfResults !== ""); _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; _ck(_v, 4, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.description; _ck(_v, 7, 0, currVal_1); var currVal_3 = _v.parent.context.$implicit.imageUrl; _ck(_v, 11, 0, currVal_3); }); }
function View_ResultsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "uk-button-text"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Show Results"]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.pdfResults; _ck(_v, 0, 0, currVal_0); }); }
function View_ResultsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-margin"], ["uk-grid", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "uk-card-media-left uk-cover-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["uk-cover", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "canvas", [["height", "400"], ["width", "600"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "uk-card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h3", [["class", "uk-card-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "p", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResultsComponent_6)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_3 = (_v.parent.context.$implicit.pdfResults !== ""); _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.imageUrl; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.name; _ck(_v, 7, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit.description; _ck(_v, 10, 0, currVal_2); }); }
function View_ResultsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "meet"], ["uk-scrollspy", "target: > div; cls: uk-animation-fade; delay: 300"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResultsComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResultsComponent_5)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = ((_v.context.index % 2) == 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_v.context.index % 2) != 0); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ResultsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "section", [["id", "results"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "uk-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Meet Results"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResultsComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResultsComponent_2)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co._data.resultsLength <= 0); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.results; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_ResultsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-results", [], null, null, null, View_ResultsComponent_0, RenderType_ResultsComponent)), i1.ɵdid(1, 114688, null, 0, i3.ResultsComponent, [i4.DataService, i5.SeoService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResultsComponentNgFactory = i1.ɵccf("app-results", i3.ResultsComponent, View_ResultsComponent_Host_0, {}, {}, []);
export { ResultsComponentNgFactory as ResultsComponentNgFactory };

import * as tslib_1 from "tslib";
import { EventFront } from './../types/eventfront';
import { User } from './../types/user';
import { Router } from '@angular/router';
import { LoginService } from './../login.service';
import { takeWhile } from 'rxjs/operators';
import { OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { DataService } from '../data.service';
import { Roster } from '../types/roster';
import { Results } from '../types/results';
var DashboardComponent = /** @class */ (function () {
    function DashboardComponent(_login, _data, router, afAuth) {
        this._login = _login;
        this._data = _data;
        this.router = router;
        this.afAuth = afAuth;
        this.stopProgress = false;
        this.createdText = 'An account has already been created';
        this.uploading = false;
        this.uploadingPDF = false;
        this.progress = 0;
        this.progressPDF = 0;
        this.noFile = true;
        this.noFilePDF = true;
        this.hidden = true;
        this.notEditable = true;
        this.favoriteEvents = [];
        this.isEditing = false;
        this.editingKey = undefined;
        this.authenticated = false;
        this.start = true;
        this.showData = this._login.processed;
        this.result = new Results(undefined, undefined, undefined, undefined);
        this.event = new EventFront(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
        this.individual = new Roster(undefined, undefined, undefined);
        this.title = 'coach';
        this.eventDate = '8/1/2017-8/13/2017';
        this.swimmers = [];
        this.divers = [];
        this.admins = [];
        this.eventModal = false;
        this.coaches = [];
        this.events = [];
        this.results = [];
        this.isFirstTime = false;
        this.gender = 'Your Gender';
        this.genderList = ['Girl', 'Boy'];
        this.bio = '';
        this.firstName = '';
        this.showProgress = false;
        this.showProgressPdf = false;
    }
    DashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._data.roster.subscribe(function (val) {
            _this.swimmers = [];
            _this.divers = [];
            _this.coaches = [];
            _this.admins = [];
            val.forEach(function (el) {
                switch (el.title) {
                    case 'swimmer':
                        _this.swimmers.push(el);
                        break;
                    case 'diver':
                        _this.divers.push(el);
                        break;
                    case 'coach':
                        _this.coaches.push(el);
                        break;
                    case 'admin':
                        _this.admins.push(el);
                        break;
                }
            });
            _this.swimmers = _this.swimmers.sort();
            _this.divers = _this.divers.sort();
            _this.coaches = _this.coaches.sort();
            _this.admins = _this.admins.sort();
        });
        this._data.events.subscribe(function (val) {
            _this.events = val;
            _this.events = _this.events.sort(function (a, b) {
                var aDate = new Date(a.startDate + " " + a.startTime);
                var bDate = new Date(b.startDate + " " + b.startTime);
                return aDate.getTime() - bDate.getTime();
            });
        });
        this._data.results.subscribe(function (val) {
            _this.results = val;
        });
        setTimeout(function () {
            _this.showData = true;
            _this._login.processed = true;
        }, 2000);
        this.afAuth.authState.subscribe(function (user) {
            if (!user) {
                _this.router.navigateByUrl('/login');
            }
            _this._login.isFirstTime.subscribe(function (val) {
                if (val) {
                    _this.isFirstTime = true;
                    console.log("this is the person's first time");
                }
                else {
                    console.log('nevermind! found an account!');
                    _this.authenticated = true;
                    _this.isFirstTime = false;
                    _this.getData();
                    _this._login.getUserPhoto();
                }
            });
        });
    };
    DashboardComponent.prototype.ngAfterViewInit = function () {
        $('input.timepicker').timepicker({ dropdown: false });
        $('.datepicker').datepicker();
    };
    DashboardComponent.prototype.getData = function () {
        var _this = this;
        var data = this._login.getUserData().subscribe(function (val) {
            if (val !== undefined) {
                _this.name = val['name'].replace(/\(.*[A-a]dmin\)/, '').trim();
                _this.title = val['title'];
            }
            setTimeout(function () {
                data.unsubscribe();
            }, 1000);
        });
    };
    DashboardComponent.prototype.toggleEditable = function () {
        this.notEditable = false;
    };
    DashboardComponent.prototype.onChangeProfile = function () {
        if (this.gender === undefined) {
            this.gender = 'Your Gender';
        }
        if (this.bio === undefined) {
            this.bio = '';
        }
        if (this.favoriteEvents === undefined) {
            this.favoriteEvents = [];
        }
        var user = {
            gender: this.gender,
            age: this.age,
            bio: this.bio,
            'favorite-events': this.favoriteEvents
        };
        Materialize.toast('Your User Profile was Updated', 2000, 'rounded');
        this._login.updateUser(user);
        this.notEditable = true;
    };
    DashboardComponent.prototype.newUserNext = function () {
        switch (this.title) {
            case 'coach':
                this.name_options = this.coaches.map(function (val) {
                    return val.name;
                });
                break;
            case 'admin':
                this.name_options = this.admins.map(function (val) {
                    return val.name;
                });
                break;
        }
        this.name = this.name_options[0];
        this.start = false;
    };
    DashboardComponent.prototype.newUserBack = function () {
        this.start = true;
        this.name_options = [];
    };
    DashboardComponent.prototype.newUserFinish = function () {
        var _this = this;
        var checked = false;
        var result = this._login
            .getAccountByName(this.name)
            .takeWhile(function () {
            return checked === false;
        });
        result.subscribe(function (val) {
            if (val.length === 0) {
                _this.authenticated = true;
                _this.user = new User(_this.name, _this.title);
                _this._login.createUser(_this.user);
                _this.isFirstTime = false;
                checked = true;
                _this._login.getUserPhoto();
                return;
            }
            else {
                _this._login.logout('/login');
                console.log('account already found');
                _this.modal = UIkit.modal('#already-created');
                _this.modal.show();
                checked = true;
            }
        });
    };
    DashboardComponent.prototype.capitalizeFirstLetter = function (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };
    DashboardComponent.prototype.redirect = function () {
        var _this = this;
        setTimeout(function () {
            _this._login.logout('/login');
            _this.modal.hide();
        }, 2000);
        this.createdText = 'Redirecting you to login page';
    };
    DashboardComponent.prototype.showModal = function (id, edit, data, type) {
        if (edit === void 0) { edit = false; }
        if (type === void 0) { type = 'event'; }
        if (id === '#add-event') {
            this.setAutocompleteGeo();
            this.eventModal = true;
            if (edit) {
                if (data !== undefined) {
                    this.isEditing = true;
                    this.editingKey = data['key'];
                    if (type === 'event') {
                        this.event = new EventFront(data['name'], data['type'], data['startDate'], data['endDate'], data['startTime'], data['endTime'], data['address'], data['description']);
                        $('.timepicker#start-time').val(data['startTime']);
                        $('.timepicker#end-time').val(data['endTime']);
                        $('.datepicker#start-date').datepicker('setDate', data['startDate']);
                        $('.datepicker#end-date').datepicker('setDate', data['endDate']);
                    }
                }
            }
            return;
        }
        else if (id === '#add-roster') {
            if (edit) {
                if (data !== undefined) {
                    this.isEditing = true;
                    this.editingKey = data['key'];
                    this.individual = new Roster(data['name'], data['title'], data['gender']);
                    if (data['grade'] !== undefined) {
                        this.individual.grade = data['grade'];
                    }
                    if (data['imageUrl'] !== undefined) {
                        this.individual.imageUrl = data['imageUrl'];
                    }
                }
            }
        }
        else if (id === '#add-result') {
            if (edit) {
                if (data !== undefined) {
                    this.isEditing = true;
                    this.editingKey = data['key'];
                    this.result = new Results(data['name'], data['description']);
                    if (data['pdfResults'] !== undefined) {
                        this.result.pdfResults = data['pdfResults'];
                    }
                    if (data['imageUrl'] !== undefined) {
                        this.result.imageUrl = data['imageUrl'];
                    }
                }
            }
        }
        UIkit.modal(id).show();
    };
    DashboardComponent.prototype.hideModal = function (id) {
        if (id === '#add-event') {
            this.eventModal = false;
            this.event = new EventFront(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
            return;
        }
        UIkit.modal(id).hide();
    };
    DashboardComponent.prototype.addEvent = function () {
        var _this = this;
        this.stopProgress = false;
        this.event.startDate = $('.datepicker#start-date').datepicker('getDate', true);
        this.event.endDate = $('.datepicker#end-date').datepicker('getDate', true);
        this.event.startTime = $('#start-time').val();
        this.event.endTime = $('#end-time').val();
        this.event.endTime = $('input[name="end-time"]')[0].value;
        this.event.address = $('input[name="event-address"]')[0].value;
        var temp_name = this.event.name;
        if (this.isEditing) {
            this._data
                .updateEvent(this.editingKey, this.event.toFirebase())
                .then(function () {
                Materialize.toast('Updated ' + temp_name + ' in the Events List', 2000, 'rounded');
                _this.resetEvent();
            })
                .catch(function (err) {
                Materialize.toast('There was a problem updating your event in the list', 5000, 'rounded');
            });
        }
        else {
            this._data
                .addEvent(this.event.toFirebase())
                .then(function () {
                Materialize.toast('Added ' + temp_name + ' to the Events List', 2000, 'rounded');
                _this.resetEvent();
            })
                .catch(function (err) {
                Materialize.toast('There was a problem adding your event to the list', 5000, 'rounded');
                _this.resetEvent();
            });
        }
    };
    DashboardComponent.prototype.setAutocompleteGeo = function () {
        var input = document.getElementById('address');
        var autocomplete = new google.maps.places.Autocomplete(input);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                var geolocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                var circle = new google.maps.Circle({
                    center: geolocation,
                    radius: position.coords.accuracy
                });
                autocomplete.setBounds(circle.getBounds());
            });
        }
    };
    DashboardComponent.prototype.addIndividual = function (reset) {
        var _this = this;
        if (reset === void 0) { reset = true; }
        var temp_name = this.individual.name;
        this.stopProgress = false;
        if (this.isEditing) {
            this._data
                .updateRoster(this.editingKey, this.individual.toFirebase())
                .then(function () {
                Materialize.toast('Updated ' + temp_name + ' in the Roster List', 2000, 'rounded');
                if (reset) {
                    _this.stopProgress = true;
                    // reset values
                    _this.editingKey = '';
                    _this.isEditing = false;
                    _this.uploading = false;
                    _this.showProgress = false;
                    _this.noFile = true;
                    _this.file = null;
                    _this.progress = 0;
                }
            })
                .catch(function (err) {
                Materialize.toast('There was a problem updating your individual in the list', 5000, 'rounded');
            });
        }
        else {
            this._data
                .addToRoster(this.individual.toFirebase())
                .then(function () {
                Materialize.toast('Added ' + temp_name + ' to the Roster List', 2000, 'rounded');
            })
                .catch(function (err) {
                Materialize.toast('There was a problem adding your individual to the list', 5000, 'rounded');
            });
        }
        if (reset) {
            this.resetIndividual();
        }
    };
    DashboardComponent.prototype.addResult = function (reset) {
        var _this = this;
        if (reset === void 0) { reset = true; }
        var temp_name = this.result.name;
        this.stopProgress = false;
        if (this.isEditing) {
            this._data
                .updateResults(this.editingKey, this.result.toFirebase())
                .then(function () {
                Materialize.toast('Updated ' + temp_name + ' in the Results List', 2000, 'rounded');
                if (reset) {
                    _this.stopProgress = true;
                    _this.editingKey = '';
                    _this.noFile = true;
                    _this.noFilePDF = true;
                    _this.isEditing = false;
                    _this.file = null;
                    _this.progress = 0;
                }
            })
                .catch(function (err) {
                Materialize.toast('There was a problem updating your result in the list', 5000, 'rounded');
            });
        }
        else {
            this._data
                .addResults(this.result.toFirebase())
                .then(function () {
                Materialize.toast('Added ' + temp_name + ' to the results List', 2000, 'rounded');
            })
                .catch(function (err) {
                Materialize.toast('There was a problem adding your result to the list', 5000, 'rounded');
            });
        }
        if (reset) {
            this.resetResult();
        }
    };
    DashboardComponent.prototype.deleteEvent = function (event) {
        this._data
            .deleteEvent(event)
            .then(function () {
            Materialize.toast('Deleted ' + event.name + ' from the Events List', 2000, 'rounded');
        })
            .catch(function (err) {
            console.error(err);
            Materialize.toast('There was a problem deleting your event from the list', 5000, 'rounded');
        });
    };
    DashboardComponent.prototype.deleteIndividual = function (individual) {
        this._data
            .deleteIndividual(individual)
            .then(function () {
            Materialize.toast('Deleted ' + individual.name + ' from the Roster List', 2000, 'rounded');
        })
            .catch(function (err) {
            console.error(err);
            Materialize.toast('There was a problem deleting your individual from the list', 5000, 'rounded');
        });
    };
    DashboardComponent.prototype.deleteResult = function (result) {
        this._data
            .deleteResults(result)
            .then(function () {
            Materialize.toast('Deleted ' + result.name + ' from the Meet Results List', 2000, 'rounded');
        })
            .catch(function (err) {
            console.error(err);
            Materialize.toast('There was a problem deleting your meet from the list', 5000, 'rounded');
        });
    };
    DashboardComponent.prototype.gradeUp = function (individual) {
        var id = individual['$key'];
        switch (individual['grade']) {
            case 'freshman':
                individual['grade'] = 'sophomore';
                break;
            case 'sophomore':
                individual['grade'] = 'junior';
                break;
            case 'junior':
                individual['grade'] = 'senior';
                break;
        }
        this._data
            .updateRoster(id, individual)
            .then(function () {
            Materialize.toast('Updated ' + individual.name + "'s grade", 2000, 'rounded');
        })
            .catch(function () {
            Materialize.toast("There was a problem updating your individual's grade", 5000, 'rounded');
        });
    };
    DashboardComponent.prototype.onUpload = function (event, pdf) {
        if (pdf === void 0) { pdf = false; }
        var eventObj = event;
        var target = eventObj.target;
        var files = target.files;
        if (pdf) {
            this.showProgressPdf = true;
            this.pdfFile = files[0];
            this.noFilePDF = false;
            return;
        }
        this.file = files[0];
        this.noFile = false;
    };
    DashboardComponent.prototype.uploadImage = function (file, data, roster) {
        if (roster === void 0) { roster = true; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var progressSubscription;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._data.uploadProgress.next(0);
                        this.showProgress = true;
                        this.stopProgress = false;
                        this.progress = 0;
                        // code to upload image
                        this._data.uploadImage(file, this.editingKey, data).then(function (val) {
                            if (roster) {
                                _this.individual.imageUrl = val;
                            }
                            else {
                                _this.result.imageUrl = val;
                            }
                        });
                        progressSubscription = this._data.getUploadProgress();
                        return [4 /*yield*/, progressSubscription
                                .pipe(takeWhile(function (progress) { return progress <= 100; }))
                                .subscribe(function (progress) {
                                _this.uploading = true;
                                if (progress > 0) {
                                    if (_this.stopProgress) {
                                        _this.progress = 0;
                                    }
                                    else {
                                        _this.progress = progress;
                                    }
                                }
                            })];
                    case 1:
                        _a.sent();
                        this.uploading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardComponent.prototype.uploadResults = function (file, data) {
        var _this = this;
        this._data.uploadPdf(file, this.editingKey, data).then(function (val) {
            _this.result.pdfResults = val;
            if (_this.editingKey) {
                _this.addResult(false);
            }
        });
        var progressSubscription = this._data
            .getUploadProgress(true)
            .pipe(takeWhile(function (progress) { return progress <= 100; }))
            .subscribe(function (progress) {
            if (progress > 0) {
                _this.uploadingPDF = true;
                _this.progressPDF = progress;
            }
            if (progress === 100) {
                _this.uploadingPDF = false;
            }
        })
            .add(function () {
            progressSubscription.unsubscribe();
            setTimeout(function () {
                Materialize.toast('Updated Results', 2000, 'rounded');
            }, 500);
        });
    };
    DashboardComponent.prototype.resetEvent = function () {
        this.stopProgress = true;
        this.event = new EventFront(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
        $('.datepicker#start-date').val('');
        $('.datepicker#end-date').val('');
        $('#start-time').val('');
        $('#end-time').val('');
        this.isEditing = false;
        this.editingKey = '';
        this.file = null;
    };
    DashboardComponent.prototype.resetResult = function () {
        this.result = new Results(undefined, undefined, undefined);
        this.isEditing = false;
        this.editingKey = '';
        this.file = null;
    };
    DashboardComponent.prototype.resetIndividual = function () {
        this.individual = new Roster(undefined, undefined, undefined);
        this.isEditing = false;
        this.editingKey = '';
        this.file = null;
    };
    DashboardComponent.metadata = {
        contentType: 'image/jpeg'
    };
    return DashboardComponent;
}());
export { DashboardComponent };

/**
 * @name: HomeComponent
 * @author: Brandon Fan
 * @description: Home Page Component
 * @version: 1.2
 */
import { GoogleAnalyticsEventsService } from './../ga.service';
import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  AfterViewInit,
  ElementRef
} from '@angular/core';
import {
  PageScrollService,
  PageScrollInstance,
  PageScrollConfig
} from 'ngx-page-scroll';
import { DOCUMENT } from '@angular/platform-browser';
import { SeoService } from '../seo.service';

declare const $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild('container') private container: ElementRef;
  interviews: Object;
  constructor(
    private gaEvent: GoogleAnalyticsEventsService,
    private _pageScrollService: PageScrollService,
    private seo: SeoService,
    @Inject(DOCUMENT) private _document: any
  ) {
    PageScrollConfig.defaultScrollOffset = 100;
    PageScrollConfig.defaultEasingLogic = {
      ease: (t: number, b: number, c: number, d: number): number => {
        // easeInOutExpo easing
        if (t === 0) {
          return b;
        }
        if (t === d) {
          return b + c;
        }
        if ((t /= d / 2) < 1) {
          return (c / 2) * Math.pow(2, 10 * (t - 1)) + b;
        }
        return (c / 2) * (-Math.pow(2, -10 * --t) + 2) + b;
      }
    };
  }
  ngAfterViewInit() {
    $('.jarallax').jarallax({
      speed: 0.2
    });
  }
  public learnMore(): void {
    let pageScrollInstance: PageScrollInstance = PageScrollInstance.simpleInstance(
      this._document,
      '#team'
    );
    this._pageScrollService.start(pageScrollInstance);
    this.gaEvent.emitEvent('Learn More', 'Clicked');
  }
  public toTop(): void {
    let pageScrollInstance: PageScrollInstance = PageScrollInstance.simpleInstance(
      this._document,
      '#jumbotron'
    );
    this._pageScrollService.start(pageScrollInstance);
  }

  ngOnInit() {
    this.seo.generateTags({
      title: 'BHS Swim & Dive Home',
      description: `We are the Blacksburg High School Swim and Dive Team. The swim team is coached by Chris Reilly who is starting his 6th year at the helm. Our goal is to provide a safe supportive environment to teach proper technique, sportsmanship and team unity.`,
      image:
        'https://firebasestorage.googleapis.com/v0/b/bhs-swim-and-dive.appspot.com/o/seo%2Fboys.jpg?alt=media&token=33c1068a-807f-43ee-b7f2-cec5302a50b8'
    });
  }
}

var Results = /** @class */ (function () {
    function Results(_name, _description, _pdfResults, _imageUrl) {
        this._name = _name;
        this._description = _description;
        this._pdfResults = _pdfResults;
        this._imageUrl = _imageUrl;
    }
    Object.defineProperty(Results.prototype, "name", {
        get: function () {
            return this._name;
        },
        set: function (value) {
            this._name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Results.prototype, "description", {
        get: function () {
            return this._description;
        },
        set: function (value) {
            this._description = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Results.prototype, "pdfResults", {
        get: function () {
            if (this._pdfResults !== undefined) {
                return this._pdfResults;
            }
            return '';
        },
        set: function (value) {
            this._pdfResults = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Results.prototype, "imageUrl", {
        get: function () {
            if (this._imageUrl !== undefined) {
                return this._imageUrl;
            }
            return '';
        },
        set: function (value) {
            this._imageUrl = value;
        },
        enumerable: true,
        configurable: true
    });
    Results.prototype.toFirebase = function () {
        return {
            name: this.name,
            description: this.description,
            pdfResults: this.pdfResults,
            imageUrl: this.imageUrl
        };
    };
    return Results;
}());
export { Results };

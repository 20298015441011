export class Roster {
  constructor(
    private _name: string,
    private _title: string,
    private _gender: string,
    private _grade?: string,
    private _age?: number,
    private _imageUrl?: string
  ) {}
  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }
  get title() {
    return this._title;
  }
  set title(value) {
    this._title = value;
  }
  get gender() {
    return this._gender;
  }
  set gender(value) {
    this._gender = value;
  }

  get grade() {
    if (this._grade !== undefined) {
      return this._grade;
    }
    return '';
  }
  set grade(value) {
    this._grade = value;
  }
  get imageUrl(): string {
    if (this._imageUrl !== undefined) {
      return this._imageUrl;
    }
    return '';
  }

  set imageUrl(value: string) {
    this._imageUrl = value;
  }

  toFirebase() {
    return {
      name: this.name,
      gender: this.gender,
      title: this.title,
      grade: this.grade,
      imageUrl: this.imageUrl
    };
  }
}

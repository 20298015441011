/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login-page.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/firebaseui-angular/firebaseui-angular.ngfactory";
import * as i3 from "firebaseui-angular";
import * as i4 from "@angular/fire/auth";
import * as i5 from "./login-page.component";
var styles_LoginPageComponent = [i0.styles];
var RenderType_LoginPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginPageComponent, data: {} });
export { RenderType_LoginPageComponent as RenderType_LoginPageComponent };
export function View_LoginPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "section", [["id", "login"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "uk-container"], ["id", "login-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "heading uk-text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Login/Signup"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "firebase-ui", [], null, null, null, i2.View_FirebaseuiAngularLibraryComponent_0, i2.RenderType_FirebaseuiAngularLibraryComponent)), i1.ɵdid(5, 245760, null, 0, i3.FirebaseuiAngularLibraryComponent, [i4.AngularFireAuth, "firebaseUIAuthConfig", i3.FirebaseuiAngularLibraryService], null, null)], function (_ck, _v) { _ck(_v, 5, 0); }, null); }
export function View_LoginPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login-page", [], null, null, null, View_LoginPageComponent_0, RenderType_LoginPageComponent)), i1.ɵdid(1, 114688, null, 0, i5.LoginPageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginPageComponentNgFactory = i1.ɵccf("app-login-page", i5.LoginPageComponent, View_LoginPageComponent_Host_0, {}, {}, []);
export { LoginPageComponentNgFactory as LoginPageComponentNgFactory };

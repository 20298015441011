<!--Navbar Component-->
<ul id="slide-out" class="side-nav">
    <li *ngIf="_login.loggedIn &&  !_login.isPending" class="uk-animation-fade">
        <a id="profile-link" materialize="dropdown" style="border: none;" [materializeParams]="[{belowOrigin: true, gutter: -20}]" class="profile-container dropdown-button" data-activates="profile-dropdown">
            <img class="profile" src="{{_login.getUserPhoto() + '?sz=64'}}" alt="profile" *ngIf="!_login.checkDefault()">
            <span id="profile-default" uk-icon="icon: user" *ngIf="_login.checkDefault()"></span>
        </a>
    </li>
    <li>
        <a routerLink="/">Home</a>
    </li>
    <li>
        <a routerLinkActive="active-side" routerLink="/roster">Roster</a>
    </li>
    <li>
        <a routerLinkActive="active-side" routerLink='/events'>Events</a>
    </li>
    <li>
        <a routerLinkActive="active-side" routerLink='/results'>Results</a>
    </li>
    <li>
        <a target="_blank" href="https://pages.donately.com/blacksburgathleticclub/campaign/bhs-swim-team">Donate</a>
    </li>
    <li *ngIf="!_login.loggedIn && !_login.isPending">
        <a routerLinkActive="active-side" routerLink='/login'>Login</a>
    </li>
    <li *ngIf="_login.loggedIn && !_login.isPending">
        <a routerLink="/dashboard" class="uk-text-center" id="profile">Profile</a>
    </li>
    <li *ngIf="_login.loggedIn && !_login.isPending">
        <a (click)="_login.logout()" href="#logOut" id="logout" class="uk-text-center">Logout</a>
    </li>
</ul>
<div class="navbar-fixed uk-animation-fade">
    <nav [ngClass]="{'top': !changeStyle, 'white': changeStyle}">
        <a href="#" data-activates="slide-out" [class.icon-black]="changeStyle" class="button-collapse">
            <i class="material-icons">menu</i>
        </a>
        <div class="container nav-wrapper">
            <div class="second-wrapper">
                <a routerLink="/" class="hide-on-large-only center brand-logo" id="mobile-title" *ngIf="changeStyle">BHS Swim & Dive</a>
                <a (click)="goTo('#jumbotron')" class="hide-on-large-only center brand-logo" id="mobile-title" *ngIf="changeStyle && isHomePage">BHS Swim & Dive</a>
                <!--Main nav for website-->
                <a routerLink="/" class="brand-logo center hide-on-med-and-down" *ngIf="changeStyle">BHS Swim & Dive</a>
                <a (click)="goTo('#jumbotron')" class="brand-logo center hide-on-med-and-down" *ngIf="changeStyle && isHomePage">BHS Swim & Dive</a>
                <ul class="left hide-on-med-and-down">
                    <li>
                        <a routerLink="/">Home</a>
                    </li>
                    <li>
                        <a routerLinkActive="active" routerLink="/roster">Roster</a>
                    </li>
                    <li>
                        <a routerLinkActive="active" routerLink='/events'>Events</a>
                    </li>
                    <li>
                        <a routerLinkActive="active" routerLink='/results'>Results</a>
                    </li>
                </ul>
                <ul class="right hide-on-med-and-down">
                    <li id="donate-wrapper">
                        <button id="donate">
              <a id="donate-link" style="all: unset" target="_blank" href="https://pages.donately.com/blacksburgathleticclub/campaign/bhs-swim-team">Donate Now
                <span style="float: right">&#x21e8;</span>
              </a>
            </button>
                    </li>
                    <li *ngIf="!_login.loggedIn && !_login.isPending">
                        <a routerLinkActive="active" id="login" routerLink='/login' title="Coach & Admin Login/Signup" uk-tooltip="pos: bottom">
                            <i class="medium material-icons">person</i>
                        </a>
                    </li>
                    <li *ngIf="_login.loggedIn &&  !_login.isPending" class="uk-animation-fade">
                        <a id="profile-link" materialize="dropdown" style="border: none;" [materializeParams]="[{belowOrigin: true, gutter: -20}]" class="profile-container dropdown-button" data-activates="profile-dropdown">
                            <img class="profile" src="{{_login.getUserPhoto() + '?sz=64'}}" alt="profile" *ngIf="!_login.checkDefault()">
                            <span id="profile-default" uk-icon="icon: user" *ngIf="_login.checkDefault()"></span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</div>
<ul class="dropdown-content hide-on-med-and-down" id="profile-dropdown" style="width: 55px; position: absolute; top: 70px; display: block; left: 1190px; margin-top:20px;">
    <li>
        <a routerLink="/dashboard" class="uk-text-center" id="profile">Profile</a>
    </li>
    <li>
        <a (click)="_login.logout()" href="#logOut" id="logout" class="uk-text-center">Logout</a>
    </li>
</ul>
export class EventFront {
  constructor(
    private _name: string,
    private _type: string,
    private _startDate: string,
    private _endDate: string,
    private _startTime: string,
    private _endTime: string,
    private _address: string,
    private _description?: string
  ) {}
  get name() {
    return this._name;
  }
  set name(value) {
    this._name = value;
  }
  get type() {
    return this._type;
  }
  set type(value) {
    this._type = value;
  }
  get startDate() {
    return this._startDate;
  }
  set startDate(value) {
    this._startDate = value;
  }
  get endDate() {
    return this._endDate;
  }
  set endDate(value) {
    this._endDate = value;
  }
  get startTime() {
    return this._startTime;
  }

  set startTime(value) {
    this._startTime = value;
  }
  get endTime() {
    return this._endTime;
  }

  set endTime(value) {
    this._endTime = value;
  }

  get address() {
    return this._address;
  }

  set address(value) {
    this._address = value;
  }

  get description() {
    if (this._description !== undefined) {
      return this._description;
    }
    return '';
  }

  set description(value) {
    this._description = value;
  }

  toFirebase() {
    return {
      name: this.name,
      type: this.type,
      startDate: this.startDate,
      endDate: this.endDate,
      startTime: this.startTime,
      endTime: this.endTime,
      address: this.address,
      description: this.description
    };
  }
}

import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireDatabase } from '@angular/fire/database';
import { map, take } from 'rxjs/operators';
var DataService = /** @class */ (function () {
    function DataService(firebaseApp, storage, _db) {
        var _this = this;
        this.firebaseApp = firebaseApp;
        this.storage = storage;
        this._db = _db;
        this.uploadProgress = new BehaviorSubject(null);
        this.uploadProgressPDF = new BehaviorSubject(null);
        this._events = this._db.list('/events');
        this._events.valueChanges().subscribe(function (val) {
            _this.eventsLength = val.length;
        });
        this._roster = this._db.list('/roster');
        this._roster.valueChanges().subscribe(function (val) {
            _this.rosterLength = val.length;
        });
        this._results = this._db.list('/results');
        this._results.valueChanges().subscribe(function (val) {
            _this.resultsLength = val.length;
        });
    }
    Object.defineProperty(DataService.prototype, "events", {
        get: function () {
            return this._events
                .snapshotChanges()
                .pipe(map(function (changes) {
                return changes.map(function (c) { return (tslib_1.__assign({ key: c.payload.key }, c.payload.val())); });
            }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataService.prototype, "roster", {
        get: function () {
            return this._roster
                .snapshotChanges()
                .pipe(map(function (changes) {
                return changes.map(function (c) { return (tslib_1.__assign({ key: c.payload.key }, c.payload.val())); });
            }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataService.prototype, "results", {
        get: function () {
            return this._results
                .snapshotChanges()
                .pipe(map(function (changes) {
                return changes.map(function (c) { return (tslib_1.__assign({ key: c.payload.key }, c.payload.val())); });
            }));
        },
        enumerable: true,
        configurable: true
    });
    DataService.prototype.addToRoster = function (individual) {
        return this._roster.push(individual);
    };
    DataService.prototype.updateRoster = function (id, individual) {
        console.log(id, individual);
        return this._roster.update(id, individual);
    };
    DataService.prototype.deleteIndividual = function (individual) {
        var id = individual['key'];
        return this._roster.remove(id);
    };
    DataService.prototype.addEvent = function (event) {
        return this._events.push(event);
    };
    DataService.prototype.deleteEvent = function (event) {
        var id = event['key'];
        return this._events.remove(id);
    };
    DataService.prototype.updateEvent = function (id, event) {
        return this._events.update(id, event);
    };
    DataService.prototype.addResults = function (result) {
        return this._results.push(result);
    };
    DataService.prototype.deleteResults = function (result) {
        var id = result['key'];
        return this._results.remove(id);
    };
    DataService.prototype.updateResults = function (id, result) {
        return this._results.update(id, result);
    };
    DataService.prototype.uploadImage = function (file, id, data, roster) {
        var _this = this;
        if (roster === void 0) { roster = true; }
        var download_url = '';
        var storage_id = id === undefined ? 'new' : id;
        var path = roster ? 'roster' : 'results';
        var profileRef = path + "/" + storage_id + "/profile.jpg";
        var task = this.storage.upload(profileRef, file);
        return new Promise(function (resolve, reject) {
            task.percentageChanges().subscribe(function (val) {
                _this.uploadProgress.next(val);
                if (val === 100) {
                    _this.storage
                        .ref(profileRef)
                        .getDownloadURL()
                        .pipe(take(10))
                        .subscribe(function (value) {
                        resolve(value);
                    });
                }
            });
        });
    };
    DataService.prototype.uploadPdf = function (file, id, data) {
        var _this = this;
        var storage_id = id === undefined ? 'new' : id;
        var path = 'results';
        var profileRef = path + "/" + storage_id + "/results.pdf";
        var task = this.storage.upload(profileRef, file);
        return new Promise(function (resolve, reject) {
            task.percentageChanges().subscribe(function (val) {
                _this.uploadProgress.next(val);
                if (val === 100) {
                    _this.storage
                        .ref(profileRef)
                        .getDownloadURL()
                        .pipe(take(1))
                        .subscribe(function (value) {
                        resolve(value);
                    });
                }
            });
        });
    };
    DataService.prototype.getUploadProgress = function (pdf) {
        if (pdf === void 0) { pdf = false; }
        if (pdf) {
            return this.uploadProgressPDF;
        }
        return this.uploadProgress;
    };
    return DataService;
}());
export { DataService };

import { Results } from './types/results';
import { Roster } from './types/roster';
import { EventFront } from './types/eventfront';
import { Injectable, Inject, OnInit } from '@angular/core';
import * as firebase from 'firebase/app';
import { BehaviorSubject } from 'rxjs';
import {
  AngularFireStorage,
  AngularFireUploadTask
} from '@angular/fire/storage';
import { AngularFireDatabase } from '@angular/fire/database';
import { FirebaseApp } from '@angular/fire';
import { map, take } from 'rxjs/operators';

@Injectable()
export class DataService {
  uploadProgress = new BehaviorSubject(null);
  uploadProgressPDF = new BehaviorSubject(null);
  private _events;
  private _roster;
  private _results;
  public eventsLength: number;
  public resultsLength: number;
  public rosterLength: number;
  private _meets;
  constructor(
    @Inject(FirebaseApp) private firebaseApp: any,
    private storage: AngularFireStorage,
    private _db: AngularFireDatabase
  ) {
    this._events = this._db.list('/events');
    this._events.valueChanges().subscribe(val => {
      this.eventsLength = val.length;
    });
    this._roster = this._db.list('/roster');
    this._roster.valueChanges().subscribe(val => {
      this.rosterLength = val.length;
    });
    this._results = this._db.list('/results');
    this._results.valueChanges().subscribe(val => {
      this.resultsLength = val.length;
    });
  }
  get events() {
    return this._events
      .snapshotChanges()
      .pipe(
        map((changes: any[]) =>
          changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
        )
      );
  }
  get roster() {
    return this._roster
      .snapshotChanges()
      .pipe(
        map((changes: any[]) =>
          changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
        )
      );
  }
  get results() {
    return this._results
      .snapshotChanges()
      .pipe(
        map((changes: any[]) =>
          changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
        )
      );
  }
  addToRoster(individual) {
    return this._roster.push(individual);
  }

  updateRoster(id, individual) {
    console.log(id, individual);
    return this._roster.update(id, individual);
  }

  deleteIndividual(individual) {
    const id = individual[ 'key' ];
    return this._roster.remove(id);
  }

  addEvent(event) {
    return this._events.push(event);
  }
  deleteEvent(event) {
    const id = event[ 'key' ];
    return this._events.remove(id);
  }
  updateEvent(id, event) {
    return this._events.update(id, event);
  }

  addResults(result) {
    return this._results.push(result);
  }
  deleteResults(result) {
    const id = result[ 'key' ];
    return this._results.remove(id);
  }
  updateResults(id, result) {
    return this._results.update(id, result);
  }

  uploadImage(file: any, id: string, data: Roster | Results, roster = true) {
    let download_url = '';
    const storage_id = id === undefined ? 'new' : id;
    const path = roster ? 'roster' : 'results';
    const profileRef = `${ path }/${ storage_id }/profile.jpg`;
    const task = this.storage.upload(profileRef, file);
    return new Promise((resolve, reject) => {
      task.percentageChanges().subscribe(val => {
        this.uploadProgress.next(val);
        if (val === 100) {
          this.storage
            .ref(profileRef)
            .getDownloadURL()
            .pipe(take(10))
            .subscribe(value => {
              resolve(value);
            });
        }
      });
    });
  }
  uploadPdf(file: any, id, data: Results) {
    const storage_id = id === undefined ? 'new' : id;
    const path = 'results';
    const profileRef = `${ path }/${ storage_id }/results.pdf`;
    const task = this.storage.upload(profileRef, file);
    return new Promise((resolve, reject) => {
      task.percentageChanges().subscribe(val => {
        this.uploadProgress.next(val);
        if (val === 100) {
          this.storage
            .ref(profileRef)
            .getDownloadURL()
            .pipe(take(1))
            .subscribe(value => {
              resolve(value);
            });
        }
      });
    });
  }
  getUploadProgress(pdf = false) {
    if (pdf) {
      return this.uploadProgressPDF;
    }
    return this.uploadProgress;
  }
}

import { EventFront } from './../types/eventfront';
import { User } from './../types/user';
import { Router } from '@angular/router';
import { LoginService } from './../login.service';
import { takeWhile } from 'rxjs/operators';


import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ElementRef
} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { DataService } from '../data.service';
import { Roster } from '../types/roster';
import { Results } from '../types/results';

declare const UIkit: any;
declare const particlesJS: any;
declare const Materialize: any;
declare const $: any;
declare const google: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [ './dashboard.component.css' ]
})
export class DashboardComponent implements OnInit, AfterViewInit {
  static metadata = {
    contentType: 'image/jpeg'
  };
  age: number;
  stopProgress = false;
  createdText = 'An account has already been created';
  uploading = false;
  uploadingPDF = false;
  @ViewChild('eventAddress') eventAddress: ElementRef;
  @ViewChild('endTime') endTime: ElementRef;
  progress = 0;
  progressPDF = 0;
  file: File;
  isDefault;
  noFile = true;
  noFilePDF = true;
  pdfFile: File;
  hidden = true;
  uploadProgress;
  notEditable = true;
  favoriteEvents: string[] = [];
  user: User;
  modal;
  isEditing = false;
  editingKey = undefined;
  authenticated = false;
  start = true;
  showData = this._login.processed;
  result = new Results(undefined, undefined, undefined, undefined);
  event = new EventFront(
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined
  );
  individual: Roster = new Roster(undefined, undefined, undefined);
  title: 'coach' | 'admin' = 'coach';
  name: string;
  eventDate: any = '8/1/2017-8/13/2017';
  name_options: string[];
  swimmers: Roster[] = [];
  divers: Roster[] = [];
  admins: Roster[] = [];
  eventModal = false;
  coaches: Roster[] = [];
  events: EventFront[] = [];
  results: Results[] = [];
  isFirstTime = false;
  gender = 'Your Gender';
  genderList: [ 'Girl', 'Boy' ] = [ 'Girl', 'Boy' ];
  bio = '';
  firstName = '';
  showProgress = false;
  showProgressPdf = false;
  constructor(
    public _login: LoginService,
    private _data: DataService,
    private router: Router,
    public afAuth: AngularFireAuth
  ) { }

  ngOnInit() {
    this._data.roster.subscribe(val => {
      this.swimmers = [];
      this.divers = [];
      this.coaches = [];
      this.admins = [];

      val.forEach(el => {
        switch (el.title) {
          case 'swimmer':
            this.swimmers.push(el);
            break;
          case 'diver':
            this.divers.push(el);
            break;
          case 'coach':
            this.coaches.push(el);
            break;
          case 'admin':
            this.admins.push(el);
            break;
        }
      });
      this.swimmers = this.swimmers.sort();
      this.divers = this.divers.sort();
      this.coaches = this.coaches.sort();
      this.admins = this.admins.sort();
    });
    this._data.events.subscribe(val => {
      this.events = val;
      this.events = this.events.sort((a: EventFront, b: EventFront) => {
        const aDate = new Date(`${ a.startDate } ${ a.startTime }`);
        const bDate = new Date(`${ b.startDate } ${ b.startTime }`);
        return aDate.getTime() - bDate.getTime();
      });
    });
    this._data.results.subscribe(val => {
      this.results = val;
    });
    setTimeout(() => {
      this.showData = true;
      this._login.processed = true;
    }, 2000);
    this.afAuth.authState.subscribe(user => {
      if (!user) {
        this.router.navigateByUrl('/login');
      }
      this._login.isFirstTime.subscribe(val => {
        if (val) {
          this.isFirstTime = true;
          console.log("this is the person's first time");
        } else {
          console.log('nevermind! found an account!');
          this.authenticated = true;
          this.isFirstTime = false;
          this.getData();
          this._login.getUserPhoto();
        }
      });
    });
  }
  ngAfterViewInit() {
    $('input.timepicker').timepicker({ dropdown: false });
    $('.datepicker').datepicker();
  }

  getData() {
    let data = this._login.getUserData().subscribe(val => {
      if (val !== undefined) {
        this.name = val[ 'name' ].replace(/\(.*[A-a]dmin\)/, '').trim();
        this.title = val[ 'title' ];
      }
      setTimeout(() => {
        data.unsubscribe();
      }, 1000);
    });
  }

  toggleEditable() {
    this.notEditable = false;
  }
  onChangeProfile() {
    if (this.gender === undefined) {
      this.gender = 'Your Gender';
    }
    if (this.bio === undefined) {
      this.bio = '';
    }
    if (this.favoriteEvents === undefined) {
      this.favoriteEvents = [];
    }
    const user = {
      gender: this.gender,
      age: this.age,
      bio: this.bio,
      'favorite-events': this.favoriteEvents
    };
    Materialize.toast('Your User Profile was Updated', 2000, 'rounded');
    this._login.updateUser(user);
    this.notEditable = true;
  }

  newUserNext() {
    switch (this.title) {
      case 'coach':
        this.name_options = this.coaches.map(val => {
          return val.name;
        });
        break;
      case 'admin':
        this.name_options = this.admins.map(val => {
          return val.name;
        });
        break;
    }
    this.name = this.name_options[ 0 ];
    this.start = false;
  }

  newUserBack() {
    this.start = true;
    this.name_options = [];
  }

  newUserFinish() {
    let checked = false;
    let result = this._login
      .getAccountByName(this.name)
      .takeWhile(() => {
        return checked === false;
      });
    result.subscribe(val => {
      if (val.length === 0) {
        this.authenticated = true;
        this.user = new User(this.name, this.title);
        this._login.createUser(this.user);
        this.isFirstTime = false;
        checked = true;
        this._login.getUserPhoto();
        return;
      } else {
        this._login.logout('/login');
        console.log('account already found');
        this.modal = UIkit.modal('#already-created');
        this.modal.show();
        checked = true;
      }
    });
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  redirect() {
    setTimeout(() => {
      this._login.logout('/login');
      this.modal.hide();
    }, 2000);
    this.createdText = 'Redirecting you to login page';
  }

  showModal(
    id: string,
    edit = false,
    data?: EventFront | Roster,
    type = 'event'
  ) {
    if (id === '#add-event') {
      this.setAutocompleteGeo();
      this.eventModal = true;
      if (edit) {
        if (data !== undefined) {
          this.isEditing = true;
          this.editingKey = data[ 'key' ];
          if (type === 'event') {
            this.event = new EventFront(
              data[ 'name' ],
              data[ 'type' ],
              data[ 'startDate' ],
              data[ 'endDate' ],
              data[ 'startTime' ],
              data[ 'endTime' ],
              data[ 'address' ],
              data[ 'description' ]
            );
            $('.timepicker#start-time').val(data[ 'startTime' ]);
            $('.timepicker#end-time').val(data[ 'endTime' ]);
            $('.datepicker#start-date').datepicker(
              'setDate',
              data[ 'startDate' ]
            );
            $('.datepicker#end-date').datepicker('setDate', data[ 'endDate' ]);
          }
        }
      }
      return;
    } else if (id === '#add-roster') {
      if (edit) {
        if (data !== undefined) {
          this.isEditing = true;
          this.editingKey = data[ 'key' ];
          this.individual = new Roster(
            data[ 'name' ],
            data[ 'title' ],
            data[ 'gender' ]
          );
          if (data[ 'grade' ] !== undefined) {
            this.individual.grade = data[ 'grade' ];
          }
          if (data[ 'imageUrl' ] !== undefined) {
            this.individual.imageUrl = data[ 'imageUrl' ];
          }
        }
      }
    } else if (id === '#add-result') {
      if (edit) {
        if (data !== undefined) {
          this.isEditing = true;
          this.editingKey = data[ 'key' ];
          this.result = new Results(data[ 'name' ], data[ 'description' ]);
          if (data[ 'pdfResults' ] !== undefined) {
            this.result.pdfResults = data[ 'pdfResults' ];
          }
          if (data[ 'imageUrl' ] !== undefined) {
            this.result.imageUrl = data[ 'imageUrl' ];
          }
        }
      }
    }
    UIkit.modal(id).show();
  }

  hideModal(id: string) {
    if (id === '#add-event') {
      this.eventModal = false;
      this.event = new EventFront(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      );
      return;
    }
    UIkit.modal(id).hide();
  }

  addEvent() {
    this.stopProgress = false;
    this.event.startDate = $('.datepicker#start-date').datepicker(
      'getDate',
      true
    );
    this.event.endDate = $('.datepicker#end-date').datepicker('getDate', true);
    this.event.startTime = $('#start-time').val();
    this.event.endTime = $('#end-time').val();
    this.event.endTime = $('input[name="end-time"]')[ 0 ].value;
    this.event.address = $('input[name="event-address"]')[ 0 ].value;
    const temp_name = this.event.name;
    if (this.isEditing) {
      this._data
        .updateEvent(this.editingKey, this.event.toFirebase())
        .then(() => {
          Materialize.toast(
            'Updated ' + temp_name + ' in the Events List',
            2000,
            'rounded'
          );
          this.resetEvent();
        })
        .catch(err => {
          Materialize.toast(
            'There was a problem updating your event in the list',
            5000,
            'rounded'
          );
        });
    } else {
      this._data
        .addEvent(this.event.toFirebase())
        .then(() => {
          Materialize.toast(
            'Added ' + temp_name + ' to the Events List',
            2000,
            'rounded'
          );
          this.resetEvent();
        })
        .catch(err => {
          Materialize.toast(
            'There was a problem adding your event to the list',
            5000,
            'rounded'
          );
          this.resetEvent();
        });
    }
  }
  setAutocompleteGeo() {
    const input = document.getElementById('address');
    const autocomplete = new google.maps.places.Autocomplete(input);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        const circle = new google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy
        });
        autocomplete.setBounds(circle.getBounds());
      });
    }
  }
  addIndividual(reset = true) {
    const temp_name = this.individual.name;
    this.stopProgress = false;
    if (this.isEditing) {
      this._data
        .updateRoster(this.editingKey, this.individual.toFirebase())
        .then(() => {
          Materialize.toast(
            'Updated ' + temp_name + ' in the Roster List',
            2000,
            'rounded'
          );
          if (reset) {
            this.stopProgress = true;
            // reset values
            this.editingKey = '';
            this.isEditing = false;
            this.uploading = false;
            this.showProgress = false;
            this.noFile = true;
            this.file = null;
            this.progress = 0;
          }
        })
        .catch(err => {
          Materialize.toast(
            'There was a problem updating your individual in the list',
            5000,
            'rounded'
          );
        });
    } else {
      this._data
        .addToRoster(this.individual.toFirebase())
        .then(() => {
          Materialize.toast(
            'Added ' + temp_name + ' to the Roster List',
            2000,
            'rounded'
          );
        })
        .catch(err => {
          Materialize.toast(
            'There was a problem adding your individual to the list',
            5000,
            'rounded'
          );
        });
    }
    if (reset) {
      this.resetIndividual();
    }
  }
  addResult(reset = true) {
    const temp_name = this.result.name;
    this.stopProgress = false;
    if (this.isEditing) {
      this._data
        .updateResults(this.editingKey, this.result.toFirebase())
        .then(() => {
          Materialize.toast(
            'Updated ' + temp_name + ' in the Results List',
            2000,
            'rounded'
          );
          if (reset) {
            this.stopProgress = true;
            this.editingKey = '';
            this.noFile = true;
            this.noFilePDF = true;
            this.isEditing = false;
            this.file = null;
            this.progress = 0;
          }
        })
        .catch(err => {
          Materialize.toast(
            'There was a problem updating your result in the list',
            5000,
            'rounded'
          );
        });
    } else {
      this._data
        .addResults(this.result.toFirebase())
        .then(() => {
          Materialize.toast(
            'Added ' + temp_name + ' to the results List',
            2000,
            'rounded'
          );
        })
        .catch(err => {
          Materialize.toast(
            'There was a problem adding your result to the list',
            5000,
            'rounded'
          );
        });
    }
    if (reset) {
      this.resetResult();
    }
  }

  deleteEvent(event) {
    this._data
      .deleteEvent(event)
      .then(() => {
        Materialize.toast(
          'Deleted ' + event.name + ' from the Events List',
          2000,
          'rounded'
        );
      })
      .catch(err => {
        console.error(err);
        Materialize.toast(
          'There was a problem deleting your event from the list',
          5000,
          'rounded'
        );
      });
  }
  deleteIndividual(individual) {
    this._data
      .deleteIndividual(individual)
      .then(() => {
        Materialize.toast(
          'Deleted ' + individual.name + ' from the Roster List',
          2000,
          'rounded'
        );
      })
      .catch(err => {
        console.error(err);
        Materialize.toast(
          'There was a problem deleting your individual from the list',
          5000,
          'rounded'
        );
      });
  }
  deleteResult(result) {
    this._data
      .deleteResults(result)
      .then(() => {
        Materialize.toast(
          'Deleted ' + result.name + ' from the Meet Results List',
          2000,
          'rounded'
        );
      })
      .catch(err => {
        console.error(err);
        Materialize.toast(
          'There was a problem deleting your meet from the list',
          5000,
          'rounded'
        );
      });
  }
  gradeUp(individual) {
    const id = individual[ '$key' ];
    switch (individual[ 'grade' ]) {
      case 'freshman':
        individual[ 'grade' ] = 'sophomore';
        break;
      case 'sophomore':
        individual[ 'grade' ] = 'junior';
        break;
      case 'junior':
        individual[ 'grade' ] = 'senior';
        break;
    }
    this._data
      .updateRoster(id, individual)
      .then(() => {
        Materialize.toast(
          'Updated ' + individual.name + "'s grade",
          2000,
          'rounded'
        );
      })
      .catch(() => {
        Materialize.toast(
          "There was a problem updating your individual's grade",
          5000,
          'rounded'
        );
      });
  }

  onUpload(event: EventTarget, pdf = false) {
    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;
    if (pdf) {
      this.showProgressPdf = true;
      this.pdfFile = files[ 0 ];
      this.noFilePDF = false;
      return;
    }
    this.file = files[ 0 ];
    this.noFile = false;
  }
  async uploadImage(file, data, roster = true) {
    this._data.uploadProgress.next(0);
    this.showProgress = true;
    this.stopProgress = false;
    this.progress = 0;
    // code to upload image
    this._data.uploadImage(file, this.editingKey, data).then((val: any) => {
      if (roster) {
        this.individual.imageUrl = val;
      } else {
        this.result.imageUrl = val;
      }
    });
    // code to update progress bar
    const progressSubscription = this._data.getUploadProgress();
    await progressSubscription
      .pipe(takeWhile(progress => progress <= 100))
      .subscribe(progress => {
        this.uploading = true;
        if (progress > 0) {
          if (this.stopProgress) {
            this.progress = 0;
          } else {
            this.progress = progress;
          }
        }
      });
    this.uploading = false;
  }
  uploadResults(file, data) {
    this._data.uploadPdf(file, this.editingKey, data).then((val: any) => {
      this.result.pdfResults = val;
      if (this.editingKey) {
        this.addResult(false);
      }
    });
    const progressSubscription = this._data
      .getUploadProgress(true)
      .pipe(takeWhile(progress => progress <= 100))
      .subscribe(progress => {
        if (progress > 0) {
          this.uploadingPDF = true;
          this.progressPDF = progress;
        }
        if (progress === 100) {
          this.uploadingPDF = false;
        }
      })
      .add(() => {
        progressSubscription.unsubscribe();
        setTimeout(() => {
          Materialize.toast('Updated Results', 2000, 'rounded');
        }, 500);
      });
  }
  resetEvent() {
    this.stopProgress = true;
    this.event = new EventFront(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    );
    $('.datepicker#start-date').val('');
    $('.datepicker#end-date').val('');
    $('#start-time').val('');
    $('#end-time').val('');
    this.isEditing = false;
    this.editingKey = '';
    this.file = null;
  }
  resetResult() {
    this.result = new Results(undefined, undefined, undefined);
    this.isEditing = false;
    this.editingKey = '';
    this.file = null;
  }
  resetIndividual() {
    this.individual = new Roster(undefined, undefined, undefined);
    this.isEditing = false;
    this.editingKey = '';
    this.file = null;
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../data.service';
import { SeoService } from '../seo.service';

@Component({
  selector: 'app-roster',
  templateUrl: './roster.component.html',
  styleUrls: ['./roster.component.css']
})
export class RosterComponent implements OnInit {
  displayRoster = [];
  allData = [];
  showDivers = true;
  showSwimmers = true;
  constructor(private _data: DataService, private seo: SeoService) {}

  ngOnInit() {
    this.seo.generateTags({
      title: 'BHS Swim & Dive Roster',
      description: `Our team roster consists of our swimmers and divers of all grade levels. We welcome all students from Blacksburg High School.`,
      image: 'https://firebasestorage.googleapis.com/v0/b/bhs-swim-and-dive.appspot.com/o/seo%2Falice_and_anna.jpg?alt=media&token=ef50e933-2576-47df-b670-5b018d7e819d',
      slug: '/roster'
    });
    this._data.roster.subscribe(val => {
      this.displayRoster = [];
      val.forEach(individual => {
        if (
          individual['title'] === 'swimmer' ||
          individual['title'] === 'diver'
        ) {
          this.allData.push(individual);
          this.displayRoster.push(individual);
        }
      });
    });
    // this.divers.nativeElement.
  }
  showChange() {
    this.displayRoster = [];
    this.allData.forEach(individual => {
      if (this.showDivers) {
        if (individual['title'] === 'diver') {
          this.displayRoster.push(individual);
        }
      }
      if (this.showSwimmers) {
        if (individual['title'] === 'swimmer') {
          this.displayRoster.push(individual);
        }
      }
      
    })
  }
  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}

var EventFront = /** @class */ (function () {
    function EventFront(_name, _type, _startDate, _endDate, _startTime, _endTime, _address, _description) {
        this._name = _name;
        this._type = _type;
        this._startDate = _startDate;
        this._endDate = _endDate;
        this._startTime = _startTime;
        this._endTime = _endTime;
        this._address = _address;
        this._description = _description;
    }
    Object.defineProperty(EventFront.prototype, "name", {
        get: function () {
            return this._name;
        },
        set: function (value) {
            this._name = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventFront.prototype, "type", {
        get: function () {
            return this._type;
        },
        set: function (value) {
            this._type = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventFront.prototype, "startDate", {
        get: function () {
            return this._startDate;
        },
        set: function (value) {
            this._startDate = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventFront.prototype, "endDate", {
        get: function () {
            return this._endDate;
        },
        set: function (value) {
            this._endDate = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventFront.prototype, "startTime", {
        get: function () {
            return this._startTime;
        },
        set: function (value) {
            this._startTime = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventFront.prototype, "endTime", {
        get: function () {
            return this._endTime;
        },
        set: function (value) {
            this._endTime = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventFront.prototype, "address", {
        get: function () {
            return this._address;
        },
        set: function (value) {
            this._address = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EventFront.prototype, "description", {
        get: function () {
            if (this._description !== undefined) {
                return this._description;
            }
            return '';
        },
        set: function (value) {
            this._description = value;
        },
        enumerable: true,
        configurable: true
    });
    EventFront.prototype.toFirebase = function () {
        return {
            name: this.name,
            type: this.type,
            startDate: this.startDate,
            endDate: this.endDate,
            startTime: this.startTime,
            endTime: this.endTime,
            address: this.address,
            description: this.description
        };
    };
    return EventFront;
}());
export { EventFront };

<!--
  Edited on July 28th, 2017
  Author: Brandon Fan
  Version: 0.0.1-alpha
-->

<!-- TODO: Need to determine result requirements -->
<section id="results">
  <div class="uk-container">
    <h1 class="heading">Meet Results</h1>
    <p *ngIf="_data.resultsLength <= 0">
      There are no meet results at this time, check back soon for updates!
    </p>
    <div
      class="meet"
      *ngFor="let result of results; let z = index"
      uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 300"
    >
      <div
        *ngIf="z % 2 == 0"
        class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-margin"
        uk-grid
      >
        <div>
          <div class="uk-card-body">
            <h3 class="uk-card-title">{{ result.name }}</h3>
            <p class="description">
              <span>{{ result.description }}</span>
            </p>
            <a
              *ngIf="result.pdfResults !== ''"
              class="uk-button-text"
              target="_blank"
              [href]="result.pdfResults"
              >Show Results</a
            >
          </div>
        </div>
        <div class="uk-card-media-right uk-cover-container">
          <img [src]="result.imageUrl" uk-cover />
          <canvas width="600" height="400"></canvas>
        </div>
      </div>
      <div
        *ngIf="z % 2 != 0"
        class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-margin"
        uk-grid
      >
        <div class="uk-card-media-left uk-cover-container">
          <img [src]="result.imageUrl" uk-cover />
          <canvas width="600" height="400"></canvas>
        </div>
        <div>
          <div class="uk-card-body">
            <h3 class="uk-card-title">{{ result.name }}</h3>
            <p class="description">
              <span>{{ result.description }}</span>
            </p>
            <a
              *ngIf="result.pdfResults !== ''"
              class="uk-button-text"
              target="_blank"
              [href]="result.pdfResults"
              >Show Results</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
